<section class="dialog-container">
  <div>
    <h2 mat-dialog-title>Select User Role</h2>
  </div>

    <mat-dialog-content>
      <app-user-selector name="userSelector"
                         [label]="'Select User'"
                         [placeholder]="'Select a User'"
                         [selectedUserIds]="selectedUserIds"
                         (valueChange)="onUserSelected($event)">
      </app-user-selector>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-raised-button class="button" type="button" (click)="close()">Cancel</button>
    </mat-dialog-actions>
</section>
