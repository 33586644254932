import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AutocompleteOptionModel } from '../../models/autocomplete-option-model';
import { NgIf, NgFor } from '@angular/common';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatChipGrid, MatChipRow, MatChipRemove, MatChipInput } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { FormsModule, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-multi-autocomplete',
  templateUrl: './multi-autocomplete.component.html',
  styleUrls: ['./multi-autocomplete.component.scss'],
  standalone: true,
  imports: [NgIf, MatFormField, MatLabel, MatChipGrid, NgFor, MatChipRow, MatIcon, MatChipRemove, MatInput, FormsModule, MatAutocompleteTrigger, MatChipInput, MatAutocomplete, MatOption],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiAutocompleteComponent),
      multi: true
    }
  ]
})
export class MultiAutocompleteComponent implements ControlValueAccessor {
  public filteredOptions: AutocompleteOptionModel[] = [];
  public searchText: string = '';
  public visible = true;
  public selectable = false;
  public removable = true;
  public addOnBlur = true;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public selectedOptions: AutocompleteOptionModel[] = [];

  @Input() isDisabled: boolean = false;
  @Input() options: AutocompleteOptionModel[] = [];
  @Input() placeholder: string = "";
  @Input() label: string = "";

  @ViewChild('optionInput') optionInput: ElementRef<HTMLInputElement>;

  private onChange: (value: AutocompleteOptionModel[]) => void = () => { };
  private onTouched: () => void = () => { };

  ngOnInit() {
    this.options = this.options.sort((a, b) =>
      (a.displayValue.toLowerCase() > b.displayValue.toLowerCase()) ? 1 : (b.displayValue.toLowerCase() > a.displayValue.toLowerCase()) ? -1 : 0);

    this.filteredOptions = this.filter('');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isDisabled']) {
      this.filteredOptions = this.filter('');
    }
  }

  public remove(option: AutocompleteOptionModel): void {
    const index = this.selectedOptions.findIndex(i => i.id == option.id);

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.notifyValueChange();
    }

    if (this.selectedOptions.length === 0) {
      this.optionInput.nativeElement.focus();
      this.optionInput.nativeElement.value = '';
    }
  }

  public optionSelected(event: MatAutocompleteSelectedEvent, auto: MatAutocomplete): void {
    const index = this.selectedOptions.findIndex(i => i.displayValue === event.option.viewValue);

    if (index >= 0) {
      this.remove(this.selectedOptions[index]);
      return;
    } else {
      const option = this.options.find(i => i.displayValue === event.option.viewValue);
      if (option) {
        this.selectedOptions.push(option);
        this.notifyValueChange();
      }
    }

    this.optionInput.nativeElement.value = '';
    auto.options.filter(i => i.selected).forEach(i => i.deselect());
    this.filter('');
  }

  public applyFilter(value: string): void {
    this.filteredOptions = this.filter(value);
  }

  private filter(value: string): AutocompleteOptionModel[] {
    if (this.options.some(i => i.id == null)) {
      this.options.splice(this.options.findIndex(i => i.id == null), 1);
    }

    if (!value || value === '') {
      return this.options;
    }

    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.displayValue.toLowerCase().includes(filterValue));
  }

  public writeValue(value: AutocompleteOptionModel[]): void {
    this.selectedOptions = value || [];
    this.filteredOptions = this.filter(this.searchText);
  }

  public registerOnChange(fn: (value: AutocompleteOptionModel[]) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.selectedOptions);
    }
  }

  public onBlur(): void {
    this.onTouched();
  }
}
