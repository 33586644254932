<mat-form-field color="accent">
  <mat-label>{{ label }}</mat-label>
  <input matInput
         #date="ngModel"
         name="datePicker"
         [matDatepicker]="datePicker"
         [(ngModel)]="value"
         [disabled]="isDisabled"
         [required]="isRequired"
         (ngModelChange)="handleValueChange($event)"
         (blur)="onBlur()" />
  <mat-hint>DD/MM/YYYY</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
  <mat-datepicker #datePicker></mat-datepicker>
  <mat-error *ngIf="date.invalid">
    {{ requiredMessage }}
  </mat-error>
</mat-form-field>
