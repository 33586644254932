<app-autocomplete name="userSelector"
                  *ngIf="isInitialised"
                  [label]="label"
                  [placeholder]="placeholder"
                  [ngModel]="value?.userId" 
                  (ngModelChange)="onUserIdChange($event)"
                  [options]="users"
                  [canBeNull]="canBeNull"
                  [isDisabled]="isDisabled"
                  [requiredMessage]="requiredMessage"
                  [isRequired]="isRequired">
</app-autocomplete>
