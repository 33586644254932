<section class="dialog-container">
  <div>
    <h2 mat-dialog-title>Send Process Email</h2>
  </div>

  <mat-dialog-content>
    <app-multi-autocomplete name="recipients"
                            *ngIf="this.authService.canCurrentUserEdit"
                            [label]="'Recipient(s)'"
                            [placeholder]="'Select Users'"
                            [options]="users"
                            [(ngModel)]="processEmail.recipients"
                            [isDisabled]="isBusy">
    </app-multi-autocomplete>

    <mat-form-field color="accent">
      <mat-label>Subject</mat-label>
      <input matInput
                [disabled]="isBusy"
                placeholder="Enter Subject..."
                name="message"
                [(ngModel)]="processEmail.subject"
                #subject="ngModel"
                required>
      <mat-error *ngIf="subject.invalid">
        Subject is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label>Message</mat-label>
      <textarea matInput
                [disabled]="isBusy"
                rows="5"
                placeholder="Enter Message..."
                name="message"
                [(ngModel)]="processEmail.message"
                #message="ngModel"
                required></textarea>
      <mat-error *ngIf="message.invalid">
        Message is required.
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="button"
            [disabled]="isBusy"
            mat-raised-button
            (click)="close()">
      Cancel
    </button>
    <button *ngIf="this.authService.canCurrentUserEdit"
            mat-raised-button
            [disabled]="isBusy"
            class="button accent-button"
            color="accent"
            (click)="sendEmail()">
      Send Message
    </button>
  </mat-dialog-actions>
</section>
