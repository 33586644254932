import { Observable, map } from "rxjs";
import { RoleGroupModel } from "../models/role-group-model";
import { Injectable } from "@angular/core";
import { RoleModel } from "../models/role-model";
import { ApiService } from "./api.service";
import { RoleRegisterModel } from "../models/role-register-model";
import { TreeNodeModel } from "../models/tree-node-model";
import { UserRoleModel } from "../models/user-role-model";

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private apiService: ApiService) {
  }

  public getRoleGroups(): Observable<Array<RoleGroupModel>> {
    return this.apiService.get("role/group/get-all")
      .pipe(map((response: Array<RoleGroupModel>) => response.map(i => new RoleGroupModel(i))));
  }

  public getRoles(): Observable<Array<RoleModel>> {
    return this.apiService.get(`role/get-all`)
      .pipe(map((response: Array<RoleModel>) => response.map(i => new RoleModel(i))));
  }

  public saveRoleGroup(model: RoleGroupModel): Observable<RoleGroupModel> {
    return this.apiService.post("role/group", model)
      .pipe(map(i => new RoleGroupModel(i)));
  }

  public saveRole(model: RoleModel): Observable<RoleModel> {
    return this.apiService.post("role", model)
      .pipe(map(i => new RoleModel(i)));
  }

  public deleteRole(id: number): Observable<void> {
    return this.apiService.post(`role/delete/${id}`, {});
  }

  public getRolesForProcess(processId: number): Observable<Array<RoleModel>> {
    return this.apiService.get(`role/get-for-process/${processId}`)
      .pipe(map((response: Array<RoleModel>) => response.map(i => new RoleModel(i))));
  }

  public deleteRoleGroup(id: number): Observable<void> {
    return this.apiService.post(`role/group/delete/${id}`, {});
  }

  public updateRoleGroup(roleId: number, roleGroupId: number): Observable<RoleModel> {
    return this.apiService.post(`role/update-group/${roleId}/${roleGroupId}`, {})
      .pipe(map(response => new RoleModel(response)));
  }

  public getRoleRegister(): Observable<Array<RoleRegisterModel>> {
    return this.apiService.get("role/register")
      .pipe(map((response: Array<RoleRegisterModel>) => response.map(i => new RoleRegisterModel(i))));
  }

  public exportRegister(): Observable<any> {
    return this.apiService.postExportRaw(`role/register-export`, {});
  }

  public getRolesTree(): Observable<Array<TreeNodeModel>> {
    return this.apiService.get("role/get-roles-tree")
      .pipe(map((response: Array<TreeNodeModel>) => response.map(i => new TreeNodeModel(i))));
  }

  public getRoleById(roleId: number): Observable<RoleModel> {
    return this.apiService.get(`role/get/${roleId}`)
      .pipe(map(response => new RoleModel(response)));
  }

  public saveUserRole(model: UserRoleModel): Observable<UserRoleModel> {
    return this.apiService.post(`role/save-user-role`, model)
      .pipe(map(i => new UserRoleModel(i)));
  }

  public deleteUserRole(userRoleId: number): Observable<void> {
    return this.apiService.post(`role/delete-user-role/${userRoleId}`, {});
  }

  public getRoleUsers(roleId: number): Observable<Array<UserRoleModel>> {
    return this.apiService.get(`role/get-role-users/${roleId}`)
      .pipe(map((response: Array<UserRoleModel>) => response.map(i => new UserRoleModel(i))));
  }
}
