import { Component, EventEmitter, Input, KeyValueDiffer, KeyValueDiffers, Output, forwardRef } from '@angular/core';
import { UserService } from '../../services/user.service';
import { UserModel } from '../../models/user-model';
import { NgIf } from '@angular/common';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { FormsModule, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss'],
  standalone: true,
  imports: [NgIf, AutocompleteComponent, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSelectorComponent),
      multi: true
    }
  ]
})
export class UserSelectorComponent implements ControlValueAccessor {
  @Input() canBeNull: boolean = true;
  @Input() isDisabled: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() requiredMessage: string = "Selection is required";
  @Input() placeholder: string = "";
  @Input() label: string = "";
  @Input() selectedUserIds: Array<number> = [];
  @Output() valueChange = new EventEmitter<UserModel | null>();

  public value: UserModel | null = null;
  public isInitialised: boolean = false;
  public users: Array<UserModel> = [];

  private valueDiffer: KeyValueDiffer<string, any> | null = null;
  private onChange: (value: UserModel | null) => void = () => { };
  private onTouched: () => void = () => { };

  constructor(private differs: KeyValueDiffers, private userService: UserService) { }

  ngOnInit(): void {
    if (this.value) {
      this.createDiffer();
    }

    this.userService.getUsers().subscribe({
      next: (response: Array<UserModel>) => {
        this.users = response.filter(i =>
          (i.userId === this.value?.userId) ||
          (!this.selectedUserIds.includes(i.userId!) && i.isEnabled)
        );
        this.isInitialised = true;
      }
    });
  }

  ngDoCheck(): void {
    if (this.value && !this.valueDiffer) {
      this.createDiffer();
    }

    if (this.valueDiffer) {
      const changes = this.valueDiffer.diff(this.value!);
      if (changes) {
        const user = this.users.find(i => i.userId === this.value?.userId);

        if (user && user.userId) {
          this.value = user;
          this.notifyValueChange();
        }
      }
    }
  }

  public writeValue(value: UserModel | null): void {
    this.value = value;

    if (this.value) {
      this.createDiffer();
    }

    this.notifyValueChange();
  }

  public registerOnChange(fn: (value: UserModel | null) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public onUserSelected(user: UserModel | null): void {
    this.value = user;
    this.notifyValueChange();
  }

  private notifyValueChange(): void {
    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }

  private createDiffer(): void {
    if (this.value && !this.valueDiffer) {
      this.valueDiffer = this.differs.find(this.value).create();
    }
  }

  public touch(): void {
    this.onTouched();
  }

  public onUserIdChange(userId: number | null): void {
    const user = this.users.find(i => i.userId === userId);

    if (user) {
      this.value = user;
    }

    this.notifyValueChange();
  }
}
