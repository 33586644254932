import { NgIf } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { LoadingSpinnerComponent } from "../../../shared/loading-spinner/loading-spinner.component";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog";
import { AutocompleteComponent } from "../../../shared/autocomplete/autocomplete.component";
import { MatButton } from "@angular/material/button";
import { CustomGroupModel } from "../../../models/custom-group-model";
import { CustomService } from "../../../services/custom.service";
import { CustomModel } from "../../../models/custom-model";
import { FormsModule } from "@angular/forms";

@Component({
  selector: 'app-custom-swap-group-dialog',
  templateUrl: './custom-swap-group-dialog.component.html',
  styleUrls: ['./custom-swap-group-dialog.component.scss'],
  standalone: true,
  imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, MatDialogContent, AutocompleteComponent, MatDialogActions, MatButton,
    FormsModule]
})
export class CustomSwapGroupDialogComponent {
  public options: Array<CustomGroupModel>;
  public selectedCustomGroupId: number | null = null;
  public isLoading: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public custom: CustomModel,
    private dialogRef: MatDialogRef<CustomSwapGroupDialogComponent>,
    private customService: CustomService) {
  }

  public ngOnInit() {
    this.customService.getCustomGroups().subscribe({
      next: (response: Array<CustomGroupModel>) => {
        this.options = response.filter(group => group.customGroupId !== this.custom.customGroupId);
        this.isLoading = false;
      }
    });
  }

  public confirmGroup() {
    if (this.selectedCustomGroupId) {
      this.customService.updateCustomGroup(this.custom.customId, this.selectedCustomGroupId).subscribe({
        next: (response: CustomModel) => {
          this.dialogRef.close(response);
        },
      })
    }
  }

  public close() {
    this.dialogRef.close();
  }
}
