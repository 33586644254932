<section class="dialog-container">
  <div>
    <h2 mat-dialog-title>{{ processRevision.number }}</h2>
  </div>

  <mat-dialog-content>
    <form id="processRevisionForm" #processRevisionForm="ngForm" (ngSubmit)="save(processRevisionForm)">
      <mat-form-field color="accent">
        <mat-label>Number</mat-label>
        <input matInput
               placeholder="Enter number..."
               name="number"
               [(ngModel)]="processRevision.number"
               #number="ngModel"
               [disabled]="isBusy"
               required>
        <mat-error *ngIf="number.invalid">
          Number is required
        </mat-error>
      </mat-form-field>

      <mat-form-field color="accent">
        <mat-label>Reason</mat-label>
        <textarea matInput
                  placeholder="Enter reason..."
                  name="reason"
                  [disabled]="isBusy"
                  [(ngModel)]="processRevision.reason"></textarea>
      </mat-form-field>

      <app-date-picker name="revisedDate"
                       [label]="'Revision Date'"
                       [(ngModel)]="processRevision.revisedDate"
                       [isDisabled]="isBusy"
                       [isRequired]="true"
                       [requiredMessage]="'Revision Date is required'"></app-date-picker>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="button" (click)="close()" [disabled]="isBusy">Close</button>
    <button type="submit" mat-raised-button color="accent" class="button accent-button" form="processRevisionForm" [disabled]="isBusy">Save</button>
  </mat-dialog-actions>
</section>
