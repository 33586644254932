import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { ProcessAuditTaskNonConformanceModel } from "../models/process-audit-task-non-conformance-model";
import { Observable, map } from "rxjs";
import { ProcessAuditTaskNonConformanceActionModel } from "../models/process-audit-task-non-conformance-action-model";

@Injectable({
  providedIn: 'root'
})
export class ProcessAuditTaskNonConformanceService {
  constructor(private apiService: ApiService) {
  }

  public saveProcessAuditTaskNonConformance(model: ProcessAuditTaskNonConformanceModel): Observable<ProcessAuditTaskNonConformanceModel> {
    return this.apiService.post("processAuditTaskNonConformance/save", model)
      .pipe(map(i => new ProcessAuditTaskNonConformanceModel(i)));
  }

  public saveProcessAuditTaskNonConformanceAction(model: ProcessAuditTaskNonConformanceActionModel): Observable<ProcessAuditTaskNonConformanceActionModel> {
    return this.apiService.post("processAuditTaskNonConformance/save-non-conformance-action", model)
      .pipe(map(i => new ProcessAuditTaskNonConformanceActionModel(i)));
  }

  public deleteProcessAuditTaskNonConformance(id: number): Observable<void> {
    return this.apiService.post(`processAuditTaskNonConformance/delete/${id}`, {});
  }

  public deleteNonConformanceAction(id: number): Observable<void> {
    return this.apiService.post(`processAuditTaskNonConformance/delete-non-conformance-action/${id}`, {});
  }

  public getProcessAuditTaskNonConformancesByProcessAuditId(processAuditId: number): Observable<Array<ProcessAuditTaskNonConformanceModel>> {
    return this.apiService.get(`processAuditTaskNonConformance/get-process-audit-task-non-conformances/${processAuditId}`)
      .pipe(map((response: Array<ProcessAuditTaskNonConformanceModel>) => response.map(i => new ProcessAuditTaskNonConformanceModel(i))));
  }

  public getProcessAuditTaskNonConformanceActionsByProcessAuditId(processAuditId: number): Observable<Array<ProcessAuditTaskNonConformanceActionModel>> {
    return this.apiService.get(`processAuditTaskNonConformance/get-actions/${processAuditId}`)
      .pipe(map((response: Array<ProcessAuditTaskNonConformanceActionModel>) => response.map(i => new ProcessAuditTaskNonConformanceActionModel(i))));
  }

  public getProcessAuditTaskNonConformanceByProcessAuditTaskId(processAuditTaskId: number): Observable<Array<ProcessAuditTaskNonConformanceModel>> {
    return this.apiService.get(`processAuditTaskNonConformance/get-process-audit-task-non-conformance-by-task-id/${processAuditTaskId}`)
      .pipe(map((response: Array<ProcessAuditTaskNonConformanceModel>) => response.map(i => new ProcessAuditTaskNonConformanceModel(i))));
  }

  public getNonConformanceActionsByNonConformanceId(processNonConformanceId: number): Observable<Array<ProcessAuditTaskNonConformanceActionModel>> {
    return this.apiService.get(`processAuditTaskNonConformance/get-non-conformance-actions/${processNonConformanceId}`)
      .pipe(map((response: Array<ProcessAuditTaskNonConformanceActionModel>) => response.map(i => new ProcessAuditTaskNonConformanceActionModel(i))));
  }

  public submitNonConformanceForClosure(processAuditTaskNonConformanceId: number): Observable<ProcessAuditTaskNonConformanceModel> {
    return this.apiService.post(`processAuditTaskNonConformance/submit-non-conformance-for-closure/${processAuditTaskNonConformanceId}`, {})
      .pipe(map(i => new ProcessAuditTaskNonConformanceModel(i)));
  }

  public rejectNonConformanceForClosure(processAuditTaskNonConformanceId: number, rejectionComment: string): Observable<ProcessAuditTaskNonConformanceModel> {
    return this.apiService.post(`processAuditTaskNonConformance/reject-non-conformance-for-closure/${processAuditTaskNonConformanceId}`, { rejectionComment })
      .pipe(map(i => new ProcessAuditTaskNonConformanceModel(i)));
  }

  public closeNonConformance(processAuditTaskNonConformanceId: number, closedComment: string): Observable<ProcessAuditTaskNonConformanceModel> {
    return this.apiService.post(`processAuditTaskNonConformance/close-non-conformance/${processAuditTaskNonConformanceId}`, { closedComment })
      .pipe(map(i => new ProcessAuditTaskNonConformanceModel(i)));
  }

  public submitNonConformanceActionForClosure(processAuditNonConformanceActionId: number): Observable<ProcessAuditTaskNonConformanceActionModel> {
    return this.apiService.post(`processAuditTaskNonConformance/submit-non-conformance-action-for-closure/${processAuditNonConformanceActionId}`, {})
      .pipe(map(i => new ProcessAuditTaskNonConformanceActionModel(i)));
  }

  public rejectNonConformanceActionForClosure(processAuditNonConformanceActionId: number, rejectionComment: string): Observable<ProcessAuditTaskNonConformanceActionModel> {
    return this.apiService.post(`processAuditTaskNonConformance/reject-non-conformance-action-for-closure/${processAuditNonConformanceActionId}`, { rejectionComment })
      .pipe(map(i => new ProcessAuditTaskNonConformanceActionModel(i)));
  }

  public closeNonConformanceAction(processAuditNonConformanceActionId: number, closedComment: string): Observable<ProcessAuditTaskNonConformanceActionModel> {
    return this.apiService.post(`processAuditTaskNonConformance/close-non-conformance-action/${processAuditNonConformanceActionId}`, { closedComment })
      .pipe(map(i => new ProcessAuditTaskNonConformanceActionModel(i)));
  }
}
