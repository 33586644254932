<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <div class="row">
    <div class="col-sm-6">
      <mat-card appearance="outlined" class="list-card">
        <mat-card-content>
          <div class="list-button-row">
            <button *ngIf="canEdit()" mat-flat-button color="accent" class="button accent-button" (click)="createStandard()">Add Standard</button>
            <button mat-flat-button routerLink="/{{authService.currentUser!.companyName}}/standard-register" color="accent" class="button accent-button">Standard Register</button>
          </div>

          <table #standardTable mat-table [dataSource]="standardTableData" matSort matSortActive="title" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
              <td mat-cell *matCellDef="let standard">
                <button mat-flat-button
                        [ngClass]="{ 'row-highlight': selectedStandard?.standardId == standard.standardId }"
                        (click)="selectStandard(standard)"
                        class="button table-button">
                  {{standard.title}}
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="controls">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let standard" class="mat-cell-end">
                <button mat-icon-button
                        color="accent"
                        (click)="editStandard(standard)"
                        matTooltip="{{ canEdit() ? 'Edit' : 'View' }}">
                  <mat-icon>{{ canEdit() ? 'edit' : 'visibility' }}</mat-icon>
                </button>
                <button *ngIf="canEdit()"
                        mat-icon-button
                        color="accent"
                        (click)="deleteStandard(standard)"
                        matTooltip="Delete">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="standardColumns"></tr>
            <tr mat-row
                [ngClass]="{ 'row-highlight': selectedStandard?.standardId == standard.standardId }"
                *matRowDef="let standard; columns: standardColumns;"></tr>
            <tr *matNoDataRow>
              <td [attr.colspan]="standardColumns.length" class="no-data-row">
                No standards found
              </td>
            </tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-sm-6">
      <mat-card appearance="outlined" class="list-card">
        <mat-card-content>
          <div class="list-button-row">
            <button *ngIf="canEdit()"
                    mat-flat-button
                    color="accent"
                    class="button accent-button"
                    [disabled]="selectedStandard == null"
                    (click)="createStandardSection()">Add Section</button>
          </div>

          <table #standardSectionTable mat-table [dataSource]="standardSectionTableData" matSort matSortActive="title" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
              <td mat-cell *matCellDef="let section">
                <button mat-flat-button
                        (click)="editStandardSection(section)"
                        class="button table-button">
                  {{section.title}}
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="controls">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let section" class="mat-cell-end">
                <button mat-icon-button
                        color="accent"
                        (click)="editStandardSection(section)"
                        matTooltip="{{ canEdit() ? 'Edit' : 'View' }}">
                  <mat-icon>{{ canEdit() ? 'edit' : 'visibility' }}</mat-icon>
                </button>
                <button *ngIf="canEdit()"
                        mat-icon-button
                        color="accent"
                        (click)="deleteStandardSection(section)"
                        matTooltip="Delete">
                  <mat-icon>delete</mat-icon>
                </button>
                <button *ngIf="canEdit() && !singleGroup()"
                        mat-icon-button
                        color="accent"
                        (click)="swapStandard(section)"
                        matTooltip="Change Group">
                  <mat-icon>swap_horiz</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="standardSectionColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: standardSectionColumns;"></tr>
            <tr *matNoDataRow>
              <td [attr.colspan]="standardSectionColumns.length" class="no-data-row">
                {{ selectedStandard == null ? 'No standard selected' : 'No sections found for the selected group' }}
              </td>
            </tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
