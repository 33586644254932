<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>{{ dialogTitle }}</h2>
  </div>

  <mat-dialog-content>
    <form id="documentForm" #documentForm="ngForm" (ngSubmit)="save(documentForm)">
      <mat-tab-group color="accent">
        <mat-tab label="Details" [disabled]="isBusy">
          <div class="mat-tab-content-container dialog-mat-tab-content-container">
            <mat-form-field color="accent" *ngIf="canEdit()">
              <mat-label>Identifier</mat-label>
              <input matInput
                     placeholder="Enter identifier..."
                     name="identifier"
                     [(ngModel)]="model.identifier"
                     [disabled]="isBusy"
                     #identifier="ngModel"
                     required>
              <mat-error *ngIf="identifier.invalid">
                Identifier is required
              </mat-error>
            </mat-form-field>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Identifier
              </label>
              <div class="readonly-content">
                {{ model.identifier }}
              </div>
            </div>

            <mat-form-field color="accent" *ngIf="canEdit()">
              <mat-label>Title</mat-label>
              <input matInput
                     placeholder="Enter title..."
                     name="title"
                     [(ngModel)]="model.title"
                     [disabled]="isBusy"
                     #title="ngModel">
            </mat-form-field>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Title
              </label>
              <div class="readonly-content">
                {{ model.title }}
              </div>
            </div>

            <mat-form-field color="accent" *ngIf="canEdit()">
              <mat-label>Description</mat-label>
              <textarea matInput
                        placeholder="Enter description..."
                        name="description"
                        [disabled]="isBusy"
                        [(ngModel)]="model.description"></textarea>
            </mat-form-field>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Description
              </label>
              <div class="readonly-content">
                {{ model.description }}
              </div>
            </div>

            <div class="form-container">
              <mat-form-field color="accent" *ngIf="canEdit()">
                <mat-label>Link</mat-label>
                <input matInput
                       placeholder="Enter link..."
                       name="link"
                       [disabled]="isBusy"
                       [(ngModel)]="model.link">
              </mat-form-field>

              <a mat-icon-button
                 *ngIf="canEdit()"
                 [disabled]="!model.link"
                 class="open-in-new"
                 color="accent"
                 href="{{model.link}}"
                 target="_blank">
                <mat-icon>open_in_new</mat-icon>
              </a>
            </div>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Link
              </label>
              <div class="readonly-content">
                <a href="{{model.link}}" target="_blank">{{ model.link }}</a>
              </div>
            </div>

            <div *ngIf="canEdit()" class="file-upload-container">
              <input type="file" #file (change)="uploadFile(file.files)" class="file-input" onclick="this.value=null;">
              <div>
                <span *ngIf="model.fileDocument && model.fileDocument.fileName"><a href="javascript:void(0);" (click)="downloadFile()">{{ model.fileDocument.fileName }}</a> </span>
                <span *ngIf="!documentFormData && !model.fileDocument">No file uploaded.</span>
                <span *ngIf="documentFormData && !model.fileDocument">{{ documentFileName }}</span>
                <button mat-mini-fab color="accent" type="button" (click)="file.click()" class="accent-button document-button" [disabled]="isBusy">
                  <mat-icon>attach_file</mat-icon>
                </button>
                <button *ngIf="model.fileDocument || documentFormData" mat-mini-fab color="accent" (click)="deleteFile()" class="accent-button document-button" [disabled]="isBusy">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                File
              </label>
              <div class="readonly-content">
                <span *ngIf="model.fileDocument && model.fileDocument.fileName"><a href="javascript:void(0);" (click)="downloadFile()">{{ model.fileDocument.fileName }}</a> </span>
              </div>
            </div>

            <mat-form-field color="accent" *ngIf="canEdit()">
              <mat-label>Version</mat-label>
              <input matInput
                     placeholder="Enter version..."
                     name="version"
                     [disabled]="isBusy"
                     [(ngModel)]="model.version">
            </mat-form-field>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Version
              </label>
              <div class="readonly-content">
                {{ model.version }}
              </div>
            </div>

            <mat-form-field color="accent" *ngIf="canEdit()">
              <mat-label>Retention</mat-label>
              <input matInput
                     placeholder="Enter retention..."
                     name="retention"
                     [disabled]="isBusy"
                     [(ngModel)]="model.retention">
            </mat-form-field>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Retention
              </label>
              <div class="readonly-content">
                {{ model.retention }}
              </div>
            </div>

            <app-date-picker name="reviewDueDate"
                             [label]="'Review Date'"
                             [(ngModel)]="model.reviewDueDate"
                             [isDisabled]="isBusy"></app-date-picker>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Review Date
              </label>
              <div class="readonly-content">
                {{ model.reviewDueDate | date: 'dd MMM yyyy' }}
              </div>
            </div>

            <mat-form-field color="accent" *ngIf="canEdit()">
              <mat-label>Review Frequency</mat-label>
              <input matInput
                     placeholder="Enter Review Frequency..."
                     name="reviewfrequency"
                     [(ngModel)]="model.reviewFrequency"
                     [disabled]="isBusy"
                     #reviewfrequency="ngModel">
            </mat-form-field>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Review Frequency
              </label>
              <div class="readonly-content">
                {{ model.reviewFrequency }}
              </div>
            </div>

            <app-autocomplete name="ownerRole"
                              *ngIf="canEdit()"
                              [label]="'Owner'"
                              [placeholder]="'Select a Role'"
                              [(ngModel)]="model.ownerRoleId"
                              [options]="roles"
                              [isDisabled]="isBusy">
            </app-autocomplete>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Owner
              </label>
              <div class="readonly-content">
                {{ getRoleTitle(model.ownerRoleId) }}
              </div>
            </div>

            <app-autocomplete name="approverRoleId"
                              *ngIf="canEdit()"
                              [label]="'Approver'"
                              [placeholder]="'Select a Role'"
                              [(ngModel)]="model.approverRoleId"
                              [options]="roles"
                              [isDisabled]="isBusy">
            </app-autocomplete>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Approver
              </label>
              <div class="readonly-content">
                {{ getRoleTitle(model.approverRoleId) }}
              </div>
            </div>

            <app-multi-autocomplete name="categories"
                                    *ngIf="canEdit()"
                                    [label]="'Categories'"
                                    [placeholder]="'Select a Category'"
                                    [options]="categories"
                                    [(ngModel)]="model.categories"
                                    [isDisabled]="isBusy">
            </app-multi-autocomplete>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Categories
              </label>
              <div class="readonly-content">
                <span *ngFor="let category of model.categories; let last = last">
                  {{category.name}}<span *ngIf="!last">,</span>
                </span>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="{{processesTabTitle}}" [disabled]="isNew || isBusy">
          <div class="mat-tab-content-container dialog-mat-tab-content-container">
            <table #documentProcessesTable mat-table [dataSource]="processesTableData" matSort matSortActive="title" matSortDirection="asc" matSortDisableClear>
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
                <td mat-cell *matCellDef="let process">
                  <button mat-flat-button type="button" (click)="openProcess(process)" class="button">
                    {{process.associationReferenceAndTitle}}
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="association">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Association</th>
                <td mat-cell *matCellDef="let process">
                  {{process.association}}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="processesColumns"></tr>
              <tr mat-row
                  *matRowDef="let process; columns: processesColumns;"></tr>
            </table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()" [disabled]="isBusy" class="button">Close</button>
    <button *ngIf="!isNew" mat-raised-button color="accent" class="button accent-button" (click)="share()" [disabled]="isBusy">Share</button>
    <button type="submit" *ngIf="canEdit()" mat-raised-button color="accent" class="button accent-button" form="documentForm" [disabled]="isBusy">Save</button>
  </mat-dialog-actions>
</section>
