import { Component, OnInit } from "@angular/core";
import { PieChartModel } from "../../models/charts/pie-chart-model";
import { BarChartModel } from "../../models/charts/bar-chart-model";
import { StackedBarChartOptions } from "../../models/charts/stacked-bar-chart-model";
import { ProcessAuditService } from "../../services/process-audit.service";
import { ProcessAuditDashboardModel } from "../../models/process-audit-dashboard-model";
import { NgIf } from "@angular/common";
import { MatCard, MatCardContent } from "@angular/material/card";
import { ChartComponent } from "ng-apexcharts";

@Component({
    selector: 'app-audit-dashboard',
    templateUrl: './audit-dashboard.component.html',
    styleUrl: './audit-dashboard.component.scss',
    standalone: true,
    imports: [NgIf, MatCard, MatCardContent, ChartComponent]
})
export class AuditDashboardComponent implements OnInit {
  public isLoading: boolean = true;
  public auditsCompletePieChart: Partial<PieChartModel>;
  public nonConformanceActionsUserChart: Partial<StackedBarChartOptions>;
  public nonConformanceStatusChart: Partial<BarChartModel>;

  constructor(private processAuditService: ProcessAuditService) {
  }

  public ngOnInit(): void {
    this.processAuditService.getProcessAuditDashboard().subscribe({
      next: (response: ProcessAuditDashboardModel) => {
        this.auditsCompletePieChart = {
          series: response.auditsPerYear.map(i => i.count),
          chart: {
            width: 380,
            type: "donut"
          },
          labels: response.auditsPerYear.map(i => i.label),
          dataLabels: {
            enabled: false,
          },
          noData: {
            text: "No data to display",
            align: "center",
            verticalAlign: "middle",
          },
          legend: {
            position: "bottom",
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ],
          colors: response.auditsPerYear.map(i => i.color),
        };

        this.nonConformanceActionsUserChart = {
          series: [
            {
              name: "Open (Non Conformance)",
              group : "nonConformance",
              data: response.openNonConformanceActionsByUser.map(i => i.count),
            },
            {
              name: "Overdue (Non Conformance)",
              group: "nonConformance",
              data: response.overdueNonConformanceActionsByUser.map(i => i.count),
            },
            {
              name: "Closed (Non Conformance)",
              group: "nonConformance",
              data: response.closedNonConformanceActionsByUser.map(i => i.count),
            },
            {
              name: "Open (Observation)",
              group: "observation",
              data: response.openObservationActionsByUser.map(i => i.count),
            },
            {
              name: "Overdue (Observation)",
              group: "observation",
              data: response.overdueObservationActionsByUser.map(i => i.count),
            },
            {
              name: "Closed (Observation)",
              group: "observation",
              data: response.closedObservationActionsByUser.map(i => i.count),
            },
          ],
          chart: {
            type: "bar",
            height: 350,
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 1,
            colors: ["#fff"]
          },
          title: {
            text: "Actions"
          },
          xaxis: {
            stepSize: 1,
            categories: response.openNonConformanceActionsByUser.map(i => i.label),
            labels: {
              formatter: (value: string,) => Math.round(parseFloat(value)).toString()
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 40
          }
        };

        this.nonConformanceStatusChart = {
          series: [
            {
              name: "Non Conformances",
              data: response.nonConformanceStatuses.map(i => i.count)
            },
            {
              name: "Observations",
              data: response.observationStatuses.map(i => i.count),
            }
          ],
          chart: {
            type: "bar",
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: response.nonConformanceStatuses.map(i => i.label),
            stepSize: 1,
            labels: {
              formatter: (value: string) => Math.round(parseFloat(value)).toString()
            },
          },
          title: {
            text: "Non Conformance Status"
          },
        };

        this.isLoading = false;
      }
    });
  }
}
