<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>{{isNew ? 'New Action' : model.reference }}</h2>
  </div>

  <mat-dialog-content>
    <form id="processAuditNonConformanceActionForm" #processAuditNonConformanceActionForm="ngForm" (ngSubmit)="save(processAuditNonConformanceActionForm)">
      <mat-tab-group color="accent">
        <mat-tab label="Details" [disabled]="isBusy">
          <div class="mat-tab-content-container dialog-mat-tab-content-container">
            <div *ngIf="!isNew" class="readonly-section">
              <label class="readonly-label">
                Reference
              </label>
              <div class="readonly-content">
                {{ model.reference }}
              </div>
            </div>

            @if ((isActionOpen || isNew) && canEdit) {
            <mat-form-field color="accent">
              <mat-label>Description</mat-label>
              <textarea matInput
                        rows="5"
                        placeholder="Enter Description..."
                        name="description"
                        [(ngModel)]="model.description"
                        [disabled]="isBusy"
                        required
                        #description="ngModel">
                </textarea>
              <mat-error *ngIf="description.invalid">
                Description is required
              </mat-error>
            </mat-form-field>

            <app-user-selector name="assignedToUser"
                               [label]="'Assigned To'"
                               [placeholder]="'Select a User'"
                               [(ngModel)]="model.assignedToUser"
                               [isDisabled]="isBusy"
                               [canBeNull]="false"
                               [isRequired]="true"
                               [requiredMessage]="'Assigned To is required'">
            </app-user-selector>

            <app-date-picker name="dueDate"
                             [label]="'Due Date'"
                             [(ngModel)]="model.dueDate"
                             [isDisabled]="isBusy || (!isNew && !this.authService.canCurrentUserAudit)"
                             [isRequired]="true"
                             [requiredMessage]="'Due Date is required'">
            </app-date-picker>

            <app-user-selector name="closureApprover"
                               [label]="'Closure Approver'"
                               [placeholder]="'Select a User'"
                               [(ngModel)]="model.closureApproverUser"
                               [isDisabled]="isBusy">
            </app-user-selector>
            } @else {
            <div class="readonly-section">
              <label class="readonly-label">
                Description
              </label>
              <div class="readonly-content">
                {{ model.description }}
              </div>
            </div>

            <div class="readonly-section">
              <label class="readonly-label">
                Assigned To
              </label>
              <div class="readonly-content">
                {{ model.assignedToUser.displayName }}
              </div>
            </div>

            <div class="readonly-section">
              <label class="readonly-label">
                Due Date
              </label>
              <div class="readonly-content">
                {{ model.dueDate | date: 'dd/MM/yyyy'}}
              </div>
            </div>

            <div class="readonly-section">
              <label class="readonly-label">
                Closure Approver
              </label>
              <div class="readonly-content">
                {{ model.closureApproverUser?.displayName }}
              </div>
            </div>

            <div class="readonly-section" *ngIf="isClosed()">
              <label class="readonly-label">
                Closed Comment
              </label>
              <div class="readonly-content">
                {{ model.closedComment }}
              </div>
            </div>
            }
          </div>

        </mat-tab>

        <mat-tab label="Supporting Documents ({{ fileDocuments.length }})" [disabled]="isBusy">
          <div class="mat-tab-content-container dialog-mat-tab-content-container">
            <div *ngIf="canEdit" class="file-upload-container">
              <input type="file" #file (change)="uploadFile(file.files)" class="file-input" onclick="this.value=null;" multiple>
              <div>
                <button *ngIf="canEdit && isActionOpen"
                        mat-flat-button
                        color="accent"
                        type="button"
                        (click)="file.click()"
                        class="button accent-button"
                        [disabled]="isBusy">
                  Upload Documents
                </button>
              </div>
            </div>

            <div *ngIf="fileNamesPendingUpload.length > 0">
              Files Pending Upload: <span *ngFor="let fileName of fileNamesPendingUpload">{{ fileName }}, </span>
            </div>

            <table #fileDocumentsTable mat-table [dataSource]="fileDocumentsTableData">
              <ng-container matColumnDef="fileName">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let fileDocument">
                  <button *ngIf="fileDocument.fileDocumentId"
                          type="button"
                          mat-flat-button
                          (click)="downloadFileDocument(fileDocument)"
                          class="button table-button">
                    {{ fileDocument.fileName }}
                  </button>
                  <span *ngIf="!fileDocument.fileDocumentId">{{ fileDocument.fileName }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="controls">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let fileDocument" class="mat-cell-end">
                  <button *ngIf="canEdit && isActionOpen"
                          type="button"
                          mat-icon-button
                          color="accent"
                          matTooltip="Delete"
                          (click)="deleteFileDocument(fileDocument)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="fileDocumentsTableColumns"></tr>
              <tr mat-row *matRowDef="let fileDocument; columns: fileDocumentsTableColumns;"></tr>
              <tr *matNoDataRow>
                <td [attr.colspan]="fileDocumentsTableColumns.length" class="no-data-row">
                  No data found
                </td>
              </tr>
            </table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="button"
            mat-raised-button
            (click)="close()"
            [disabled]="isBusy">
      Close
    </button>

    <button *ngIf="canSubmitForClosure() && !isNew"
            class="button"
            mat-raised-button
            [disabled]="isBusy"
            (click)="trySubmitActionForClosure()">
      Submit for Closure
    </button>

    <button *ngIf="canClose()"
            class="button red-button"
            mat-raised-button
            [disabled]="isBusy"
            (click)="rejectClosure()">
      Reject Closure
    </button>

    <button *ngIf="canClose()"
            class="button green-button"
            mat-raised-button
            [disabled]="isBusy"
            (click)="closeNonConformanceAction()">
      Close Action
    </button>

    <button *ngIf="canEdit && isActionOpen"
            type="submit"
            mat-raised-button
            color="accent"
            class="button accent-button"
            form="processAuditNonConformanceActionForm"
            [disabled]="isBusy">
      Save
    </button>
  </mat-dialog-actions>
</section>
