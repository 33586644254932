<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>{{ dialogTitle }}</h2>
  </div>

  <mat-dialog-content>
    <form id="roleForm" #roleForm="ngForm" (ngSubmit)="save(roleForm)">
      <mat-tab-group color="accent">
        <mat-tab label="Details" [disabled]="isBusy">
          <div class="mat-tab-content-container dialog-mat-tab-content-container">
            <mat-form-field color="accent" *ngIf="canEdit()">
              <mat-label>Title</mat-label>
              <input matInput
                     placeholder="Enter title..."
                     name="title"
                     [(ngModel)]="model.title"
                     #title="ngModel"
                     [disabled]="isBusy"
                     required>
              <mat-error *ngIf="title.invalid">
                Title is required
              </mat-error>
            </mat-form-field>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Title
              </label>
              <div class="readonly-content">
                {{ model.title }}
              </div>
            </div>

            <mat-form-field color="accent" *ngIf="canEdit()">
              <mat-label>Description</mat-label>
              <textarea matInput
                        placeholder="Enter description..."
                        name="description"
                        [disabled]="isBusy"
                        [(ngModel)]="model.description"></textarea>
            </mat-form-field>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Description
              </label>
              <div class="readonly-content">
                {{ model.description }}
              </div>
            </div>

            <mat-form-field color="accent" *ngIf="canEdit()">
              <mat-label>Email Address</mat-label>
              <input matInput
                     placeholder="Enter email address..."
                     name="emailAddress"
                     [(ngModel)]="model.emailAddress"
                     [disabled]="isBusy">
            </mat-form-field>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Email Address
              </label>
              <div class="readonly-content">
                {{model.emailAddress}}
              </div>
            </div>

            <app-autocomplete name="reportsToRole"
                              *ngIf="canEdit()"
                              [label]="'Reports To'"
                              [placeholder]="'Select a Role'"
                              [(ngModel)]="model.reportsToRoleId"
                              [options]="roles"
                              [isDisabled]="isBusy">
            </app-autocomplete>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Reports To
              </label>
              <div class="readonly-content">
                {{ getRoleTitle(model.reportsToRoleId) }}
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="{{ userTabTitle }}" [disabled]="isNew || isBusy">
          <div class="mat-tab-content-container dialog-mat-tab-content-container">
            <button mat-raised-button color="accent" class="button accent-button" type="button" (click)="addUserRole()">Add User</button>
            <table #userRoleTable mat-table [dataSource]="userRolesTableData" matSort matSortActive="userDisplayName" matSortDirection="asc" matSortDisableClear>
              <ng-container matColumnDef="displayName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="userDisplayName">Name</th>
                <td mat-cell *matCellDef="let userRole">
                  {{userRole.userDisplayName}}
                </td>
              </ng-container>

              <ng-container matColumnDef="controls">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let userRole" class="mat-cell-end">
                  <button mat-icon-button color="accent" type="button" (click)="deleteUserRole(userRole)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="userRolesColumns"></tr>
              <tr mat-row *matRowDef="let userRole; columns: userRolesColumns;"></tr>
            </table>
          </div>
        </mat-tab>

        <mat-tab label="{{processesTabTitle}}" [disabled]="isNew || isBusy">
          <div class="mat-tab-content-container dialog-mat-tab-content-container">
            <table #roleProcessesTable mat-table [dataSource]="processesTableData" matSort matSortActive="title" matSortDirection="asc" matSortDisableClear>
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
                <td mat-cell *matCellDef="let process">
                  <button mat-flat-button type="button" (click)="openProcess(process)">
                    {{process.associationReferenceAndTitle}}
                  </button>
                </td>
              </ng-container>

            <ng-container matColumnDef="association">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Association</th>
              <td mat-cell *matCellDef="let process">
                {{process.association}}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="processesColumns"></tr>
            <tr mat-row
                *matRowDef="let process; columns: processesColumns;"></tr>
          </table>
        </div>
      </mat-tab>

        <mat-tab label="{{documentTabTitle}}" [disabled]="isNew || isBusy">
          <div class="mat-tab-content-container dialog-mat-tab-content-container">
            <table #documentsTable mat-table [dataSource]="documentTableData" matSort matSortActive="documentName" matSortDirection="asc" matSortDisableClear>
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="documentName">Title</th>
                <td mat-cell *matCellDef="let document">
                  <button mat-flat-button
                          type="button"
                          class="button table-button"
                          (click)="openDocument(document.documentId)">
                    {{document.documentName}}
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="association">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="association">Association</th>
                <td mat-cell *matCellDef="let document">
                  {{document.association}}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="documentColumns"></tr>
              <tr mat-row *matRowDef="let document; columns: documentColumns"></tr>
            </table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="button" (click)="close()" [disabled]="isBusy">Close</button>
    <button *ngIf="!isNew" mat-raised-button color="accent" class="button accent-button" (click)="share()" [disabled]="isBusy">Share</button>
    <button type="submit" *ngIf="canEdit()" mat-raised-button color="accent" class="button accent-button" form="roleForm" [disabled]="isBusy">Save</button>
  </mat-dialog-actions>
</section>

