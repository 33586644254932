<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>Select Custom</h2>
  </div>

  <mat-dialog-content>
    <app-autocomplete name="custom"
                      [label]="'Customs'"
                      [placeholder]="'Select a Custom'"
                      [(ngModel)]="customSelectionId"
                      [options]="customOptions"
                      [canBeNull]="false">
    </app-autocomplete>

    <mat-form-field color="accent">
      <mat-label>Value</mat-label>
      <input matInput
             placeholder="Enter value..."
             name="value"
             [(ngModel)]="value">
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="button" (click)="close()">Cancel</button>
    <button mat-raised-button color="accent" class="button accent-button" (click)="save()">Save</button>
  </mat-dialog-actions>
</section>
