import { Component, Inject } from "@angular/core";
import { ProcessAuditModel } from "../../../../models/process-audit-model";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { AuthService } from "../../../../services/auth.service";
import { ToastrService } from "ngx-toastr";
import { ProcessAuditService } from "../../../../services/process-audit.service";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { MatFormField, MatLabel, MatHint, MatSuffix, MatError } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { NgIf, DatePipe } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { DatePickerComponent } from "../../../../shared/date-picker/date-picker.component";
import { ProcessAuditStatusConstant } from "../../../../constants/process-audit-status-constant";

@Component({
    selector: 'app-process-audit-closed-dialog',
    templateUrl: './process-audit-closed-dialog.component.html',
    styleUrls: ['./process-audit-closed-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatFormField,
    MatLabel, MatInput, FormsModule, MatSuffix, NgIf, MatError, MatDialogActions,
    MatButton, DatePipe, DatePickerComponent]
})

export class ProcessAuditClosedDialogComponent {
  public processAudit: ProcessAuditModel;

  public isBusy: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public model: ProcessAuditModel,
    private dialogRef: MatDialogRef<ProcessAuditClosedDialogComponent>,
    public authService: AuthService,
    private toastr: ToastrService,
    private processAuditService: ProcessAuditService) {
    this.processAudit = structuredClone(model);
  }

  public canClose(): boolean {
    return this.model.status === ProcessAuditStatusConstant.PendingClosure &&
      (this.authService.currentUser?.isAdministrator ||
      this.authService.currentUser?.isCompanyAdministrator ||
      this.authService.currentUser?.userId == this.model.closureApproverUser?.userId)
  }

  public closeAudit() {
    if (!this.processAudit.closedComment) {
      this.toastr.error("Closed Comment is required.");
      return;
    } else if (!this.processAudit.nextReviewDate) {
      this.toastr.error("Next Review Date is required.");
      return;
    } else if (!confirm("Are you sure you want to close this Audit?")) {
      return;
    }

    this.processAuditService.closeProcessAudit(this.processAudit.processAuditId, this.processAudit.closedComment, this.processAudit.nextReviewDate).subscribe({
      next: () => {
        this.toastr.success("Audit Closed")
        this.dialogRef.close(this.processAudit.closedComment);
      }
    })
  }

  public close() {
    this.dialogRef.close();
  }
}
