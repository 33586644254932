<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>Select Process</h2>
  </div>

  <mat-dialog-content>
    <app-autocomplete name="process"
                      [label]="'Process'"
                      [placeholder]="'Select a Process'"
                      [(ngModel)]="processSelectionId"
                      [options]="processOptions"
                      [canBeNull]="false">
    </app-autocomplete>

    <app-autocomplete name="processTask"
                      [label]="'Process Task'"
                      [placeholder]="'Select a Process Task'"
                      [(ngModel)]="processTaskSelectionId"
                      [options]="processTaskOptions"
                      [isDisabled]="processSelectionId == null"
                      [canBeNull]="true">
    </app-autocomplete>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="button" (click)="close()">Cancel</button>
    <button mat-raised-button color="accent" class="button accent-button" (click)="save()">Save</button>
  </mat-dialog-actions>
</section>
