<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>Select Resource</h2>
  </div>

  <mat-dialog-content>
    <app-autocomplete name="resource"
                      [label]="'Resources'"
                      [placeholder]="'Select a Resource'"
                      [(ngModel)]="resourceSelectionId"
                      [options]="resourceOptions"
                      [canBeNull]="false">
    </app-autocomplete>

    <mat-form-field color="accent">
      <mat-label>Quantity</mat-label>
      <input matInput
             placeholder="Enter quantity..."
             name="quantity"
             [(ngModel)]="quantity">
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="button" (click)="close()">Cancel</button>
    <button mat-raised-button color="accent" class="button accent-button" (click)="save()">Save</button>
  </mat-dialog-actions>
</section>
