<section class="dialog-container">
  <div>
    <h2 mat-dialog-title>Close Audit</h2>
  </div>

  <mat-dialog-content>
    <div class="readonly-section">
      <label class="readonly-label">
        Current Review Date
      </label>
      <div class="readonly-content">
        @if (processAudit.processReviewDate) {
          {{ processAudit.processReviewDate | date: 'dd/MM/yyyy' }}
        } @else {
          None
        }
      </div>
    </div>

    <app-date-picker name="nextReviewDate"
                     [label]="'Next Review Date'"
                     [(ngModel)]="processAudit.nextReviewDate"
                     [isDisabled]="isBusy"
                     [isRequired]="true"
                     [requiredMessage]="'Next Review Date is required'"></app-date-picker>

    <mat-form-field color="accent">
      <mat-label>Closed Comment</mat-label>
      <textarea matInput
                rows="5"
                placeholder="Enter Closed Comment..."
                name="closedComment"
                [(ngModel)]="processAudit.closedComment"
                #closedComment="ngModel"
                required></textarea>
      <mat-error *ngIf="closedComment.invalid">
        Closed Comment is required
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="button" mat-raised-button (click)="close()">Cancel</button>
    <button *ngIf="canClose()"
            mat-raised-button
            class="button green-button"
            (click)="closeAudit()">
      Close Audit
    </button>
  </mat-dialog-actions>
</section>
