import { UserModel } from "./user-model"
export class ProcessEmailModel {
  public processId: number;
  public processGroupId: number;
  public message: string;
  public subject: string;
  public recipients: UserModel[] = [];

  constructor(data?: any) {
    if (data) {
      if (data.recipients) {
        data.recipients = new UserModel(data.recipients);
      }
      Object.assign(this, data);
    }
  }
}
