import { NgIf } from '@angular/common';
import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepickerInput, MatDatepickerToggle, MatDatepicker } from '@angular/material/datepicker';
import { MatError, MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-date-picker',
  standalone: true,
  imports: [NgIf, MatFormField, MatInput, MatLabel, MatDatepickerInput, MatDatepickerToggle, MatDatepicker, FormsModule, MatError, MatHint, MatIcon, MatSuffix],
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true
  }]
})
export class DatePickerComponent implements ControlValueAccessor {
  @Input() label: string = '';
  @Input() isDisabled: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() requiredMessage: string = 'Date is required';
  public value: Date | null = null;

  private onChange: (value: any) => void = () => { };
  private onTouched: () => void = () => { };

  public writeValue(value: Date | null): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public handleValueChange(value: Date | null): void {
    if (value) {
      const timeZoneOffset = value.getTimezoneOffset() * 60000;
      this.value = new Date(value.getTime() - timeZoneOffset);
    } else {
      this.value = null;
    }
    this.onChange(this.value);
  }

  public onBlur(): void {
    this.onTouched();
  }
}
