import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { CustomModel } from '../../models/custom-model';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow } from '@angular/material/table';
import { CustomGroupModel } from '../../models/custom-group-model';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { CustomService } from '../../services/custom.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { MatTableUtility } from '../../utility/mat-table-utility';
import { CustomDialogComponent } from '../custom-lists/custom-dialog/custom-dialog.component';
import { CustomGroupDialogComponent } from './custom-group-dialog/custom-group-dialog.component';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { NgIf, NgClass } from '@angular/common';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { CustomSwapGroupDialogComponent } from './custom-swap-group-dialog/custom-swap-group-dialog.component';

@Component({
    selector: 'app-custom-list',
    templateUrl: './custom-list.component.html',
    styleUrls: ['./custom-list.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatCard, MatCardContent, MatButton, RouterLink, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, NgClass, MatIconButton, MatTooltip, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow]
})

export class CustomListComponent implements OnInit {
  private route = inject(ActivatedRoute);

  public customGroups: Array<CustomGroupModel>;
  public isLoading: boolean;
  public selectedCustomGroup: CustomGroupModel | null = null;

  public customTableData = new MatTableDataSource<CustomModel>([]);
  public customColumns: string[] = ['title', 'controls'];

  public customGroupTableData = new MatTableDataSource<CustomGroupModel>([]);
  public customGroupColumns: string[] = ['title', 'controls'];

  private customSort: MatSort;
  private customGroupSort: MatSort;

  @ViewChild('customGroupTable', { read: MatSort, static: false }) set customGroupSortValue(value: MatSort) {
    if (value) {
      this.customGroupSort = value;
      this.customGroupTableData.sort = this.customGroupSort;
    }
  };

  @ViewChild('customTable', { read: MatSort, static: false }) set customSortValue(value: MatSort) {
    if (value) {
      this.customSort = value;
      this.customTableData.sort = this.customSort;
    }
  };

  constructor(private customService: CustomService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public authService: AuthService) {
    }

  public ngOnInit(): void {
    let groupIdParam = Number(this.route.snapshot.paramMap.get("groupId"));
    let customIdParam = Number(this.route.snapshot.paramMap.get("customId"));

    this.isLoading = true;

    this.customTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;
    this.customGroupTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;

    this.customService.getCustomGroups().subscribe({
      next: (response: Array<CustomGroupModel>) => {
        this.customGroups = response;
        this.refreshCustomGroupTableData();

        if (groupIdParam != 0 && customIdParam != 0) {
          const customGroup = this.customGroups.find(group => group.customGroupId === groupIdParam);
          const custom = customGroup?.customs.find(custom => custom.customId == customIdParam);
          if (customGroup && custom) {
            this.selectedCustomGroup = customGroup;
            this.refreshCustomTableData();
            this.editCustom(custom)
          }
        } else if (groupIdParam != 0 && customIdParam == 0) {
          const customGroup = this.customGroups.find(group => group.customGroupId == groupIdParam);
          if (customGroup) {
            this.selectedCustomGroup = customGroup;
            this.refreshCustomTableData();
            this.editCustomGroup(customGroup);
          }
        }

        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public singleGroup(): boolean {
    return this.customGroups.length <= 1;
  }

  public selectCustomGroup(customGroup: CustomGroupModel) {
    if (this.selectedCustomGroup != customGroup) {
      this.selectedCustomGroup = customGroup;
      this.refreshCustomTableData();
    }
  }

  public createCustomGroup() {
    if (!this.canEdit()) {
      return;
    }

    const customGroup = new CustomGroupModel();

    const dialogConfig: MatDialogConfig = {
      data: {
        ...customGroup,
      }
    };

    const dialogRef = this.dialog.open(CustomGroupDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: CustomGroupModel) => {
        if (result) {
          this.customGroups.push(result);
          this.selectedCustomGroup = result;
          this.refreshCustomTableData();
          this.refreshCustomGroupTableData();
        }
      }
    });
  }

  public editCustomGroup(customGroup: CustomGroupModel) {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...customGroup,
      },
      autoFocus: this.canEdit(),
    };

    const dialogRef = this.dialog.open(CustomGroupDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: CustomGroupModel) => {
        if (result) {
          const index = this.customGroups.findIndex(i => i.customGroupId == result.customGroupId);
          this.customGroups[index] = result;
          this.refreshCustomGroupTableData();
        }
      }
    });
  }

  public deleteCustomGroup(customGroup: CustomGroupModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Custom Group "${customGroup.title}"?`)) {
      return;
    }

    this.customService.deleteCustomGroup(customGroup.customGroupId).subscribe({
      next: () => {
        this.customGroups.splice(this.customGroups.indexOf(customGroup), 1);
        this.refreshCustomGroupTableData();
        this.toastr.success(`Custom "${customGroup.title}" deleted`);
      }
    });
  }

  public createCustom() {
    if (!this.canEdit()) {
      return;
    }

    if (!this.selectedCustomGroup) {
      return;
    }

    const custom = new CustomModel();
    custom.customGroupId = this.selectedCustomGroup.customGroupId;

    const dialogConfig: MatDialogConfig = {
      data: {
        ...custom
      }
    };

    const dialogRef = this.dialog.open(CustomDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: CustomModel) => {
        if (result) {
          this.selectedCustomGroup!.customs.push(result);
          this.refreshCustomTableData();
        }
      }
    });
  }

  public editCustom(custom: CustomModel) {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...custom,
      }
    };

    const dialogRef = this.dialog.open(CustomDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: CustomModel) => {
        if (result) {
          const customGroup = this.customGroups.find(i => i.customGroupId == result.customGroupId);
          if (customGroup) {
            const index = customGroup.customs.findIndex(i => i.customId == result.customId);
            customGroup.customs[index] = result;
            this.refreshCustomTableData();
          }
        }
      }
    });
  }

  public deleteCustom(custom: CustomModel) {
    if (!this.canEdit()) {
      return
    }

    if (!confirm(`Are you sure you want to delete Group "${custom.title}"?`)) {
      return;
    }

    this.customService.deleteCustom(custom.customId).subscribe({
      next: () => {
        const customGroup = this.customGroups.find(i => i.customGroupId == custom.customGroupId);
        if (customGroup) {
          customGroup.customs.splice(customGroup.customs.indexOf(custom), 1);
          this.refreshCustomTableData();
          this.toastr.success(`Group "${custom.title}" deleted`);
        }
      }
    });
  }

  public swapCustomGroup(custom: CustomModel) {
    if (!this.canEdit() || !this.selectedCustomGroup) {
      return;
    }

    const dialogConfig: MatDialogConfig = {
      data: {
        ...custom
      },
      width: '60%'
    };

    const dialogRef = this.dialog.open(CustomSwapGroupDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: CustomModel) => {
        if (result) {
          const previousGroup = this.customGroups.find(group => group.customGroupId === custom.customGroupId);
          if (previousGroup) {
            previousGroup.customs = previousGroup.customs.filter(i => i.customId !== custom.customId);
          }

          const newGroup = this.customGroups.find(group => group.customGroupId === result.customGroupId);
          if (newGroup) {
            newGroup.customs.push(result);
          }

          this.refreshCustomGroupTableData();
          this.refreshCustomTableData();
        }
      }
    });
  }

  private refreshCustomGroupTableData(): void {
    this.customGroupTableData.data = this.customGroups;
  }

  private refreshCustomTableData(): void {
    if (this.selectedCustomGroup) {
      this.customTableData.data = this.selectedCustomGroup.customs;
    } else {
      this.customTableData.data = [];
    }
  }
}
