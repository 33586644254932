<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <div class="row">
    <div class="col-sm-12">
      <h2>Details</h2>
      <form id="userForm" #userForm="ngForm" (ngSubmit)="save(userForm)">
        <mat-form-field color="accent">
          <mat-label>Name</mat-label>
          <input matInput
                 placeholder="Enter display name..."
                 [(ngModel)]="user.displayName"
                 #displayName="ngModel"
                 [disabled]="isBusy"
                 name="displayName"
                 required />
          <mat-error *ngIf="displayName.invalid">
            Display Name is required
          </mat-error>
        </mat-form-field>

        <mat-form-field color="accent">
          <mat-label>Email</mat-label>
          <input matInput
                 placeholder="Enter email address..."
                 [(ngModel)]="user.emailAddress"
                 #emailAddress="ngModel"
                 [disabled]="isBusy || !isNew"
                 name="emailAddress"
                 required />
          <mat-error *ngIf="emailAddress.invalid">
            Email is required
          </mat-error>
        </mat-form-field>
      </form>

        <div>
          <mat-checkbox [(ngModel)]="user.isEnabled" [disabled]="isBusy">Is Enabled?</mat-checkbox>
        </div>

        <div>
          <mat-checkbox [(ngModel)]="user.isCompanyAdministrator" [disabled]="isBusy || !authService.currentUser!.isAdministrator">Is Company Administrator?</mat-checkbox>
        </div>

        <div>
          <mat-checkbox [(ngModel)]="user.isEditor" [disabled]="isBusy || !authService.currentUser!.isAdministrator">Is Editor?</mat-checkbox>
        </div>

        <div *ngIf="isAuditsEnabled()">
          <mat-checkbox [(ngModel)]="user.isAuditor" [disabled]="isBusy || !authService.currentUser!.isAdministrator">Is Auditor?</mat-checkbox>
        </div>
      

      <div class="button-row">
        <button type="submit" mat-flat-button color="accent" class="button accent-button" form="userForm" [disabled]="isBusy">Save</button>
        <button *ngIf="!isNew && !user.isVerified"
                mat-flat-button color="accent"
                class="button accent-button"
                (click)="resendVerificationEmail()"
                [disabled]="isBusy">
          Resend Verification Email
        </button>
      </div>
    </div>
  </div>
</div>
