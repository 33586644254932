import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ProcessGroupModel } from '../../models/process-group-model';
import { ProcessService } from '../../services/process.service';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow } from '@angular/material/table';
import { ProcessModel } from '../../models/process-model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProcessGroupDialogComponent } from './process-group-dialog/process-group-dialog.component';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableUtility } from '../../utility/mat-table-utility';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { NgIf, NgClass } from '@angular/common';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { environment } from '../../../environments/environment';
import { ProcessSwapGroupDialogComponent } from './process-swap-group-dialog/process-swap-group-dialog.component';

@Component({
    selector: 'app-process-list',
    templateUrl: './process-list.component.html',
    styleUrls: ['./process-list.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatCard, MatCardContent, MatButton, RouterLink, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, NgClass, MatIconButton, MatTooltip, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow]
})

export class ProcessListComponent implements OnInit {
  private route = inject(ActivatedRoute);

  public isLoading: boolean;

  public processGroups: Array<ProcessGroupModel> = [];
  public processGroupTableData = new MatTableDataSource<ProcessGroupModel>([]);
  public processGroupColumns: string[] = ['title', 'controls'];
  public selectedProcessGroup: ProcessGroupModel | null = null;

  public processTableData = new MatTableDataSource<ProcessModel>([]);
  public processColumns: string[] = ['title', 'controls'];

  private processGroupSort: MatSort;
  private processSort: MatSort;

  @ViewChild('processGroupTable', { read: MatSort, static: false }) set processGroupSortValue(value: MatSort) {
    if (value) {
      this.processGroupSort = value;
      this.processGroupTableData.sort = this.processGroupSort;
    }
  };

  @ViewChild('processTable', { read: MatSort, static: false }) set processSortValue(value: MatSort) {
    if (value) {
      this.processSort = value;
      this.processTableData.sort = this.processSort;
    }
  };

  constructor(private router: Router,
    private processService: ProcessService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public authService: AuthService) {
  }

  // TODO TEMP
  public isAuditsEnabled(): boolean {
    return environment.instance == "test" || environment.instance == "dev" || this.authService.currentUser?.companyId == 1;
  }

  public ngOnInit(): void {
    let groupIdParam = Number(this.route.snapshot.paramMap.get("groupId"));
    this.isLoading = true;

    this.processGroupTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;
    this.processTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;

    this.processService.getProcessGroups().subscribe({
      next: (response: Array<ProcessGroupModel>) => {
        this.processGroups = response;
        this.refreshProcessGroupTableData();

        if (groupIdParam != 0) {
          const group = this.processGroups.find(i => i.processGroupId == groupIdParam);
          if (group) {
            this.selectedProcessGroup = this.selectedProcessGroup = group;
            this.refreshProcessTableData();
            this.editProcessGroup(this.selectedProcessGroup);
          }
        }

        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public singleGroup(): boolean {
    return this.processGroups.length <= 1;
  }

  public createProcessGroup() {
    if (!this.canEdit()) {
      return;
    }

    const processGroup = new ProcessGroupModel();

    const dialogConfig: MatDialogConfig = {
      data: {
        ...processGroup,
      }
    };

    const dialogRef = this.dialog.open(ProcessGroupDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessGroupModel) => {
        if (result) {
          this.processGroups.push(result);
          this.selectedProcessGroup = result;
          this.refreshProcessGroupTableData();
          this.refreshProcessTableData();
        }
      }
    });
  }

  public editProcessGroup(processGroup: ProcessGroupModel) {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...processGroup,
      }
    };

    const dialogRef = this.dialog.open(ProcessGroupDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessGroupModel) => {
        if (result) {
          const index = this.processGroups.findIndex(i => i.processGroupId == result.processGroupId);
          this.processGroups[index] = result;
          this.refreshProcessGroupTableData();
          if (this.selectedProcessGroup?.processGroupId == result.processGroupId) {
            this.selectedProcessGroup = result;
            this.refreshProcessTableData();
          }
        }
      }
    });
  }

  public deleteProcessGroup(processGroup: ProcessGroupModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Process Group "${processGroup.title}"?`)) {
      return;
    }

    this.processService.deleteProcessGroup(processGroup.processGroupId).subscribe({
      next: () => {
        this.processGroups.splice(this.processGroups.indexOf(processGroup), 1);
        this.refreshProcessGroupTableData();
        this.toastr.success(`Process Group "${processGroup.title}" deleted`);
      }
    })
  }

  public swapProcessGroup(process: ProcessModel) {
    if (!this.canEdit() || !this.selectedProcessGroup) {
      return;
    }

    const dialogConfig: MatDialogConfig = {
      data: {
        ...process
      },
      width: '60%'
    };

    const dialogRef = this.dialog.open(ProcessSwapGroupDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessModel) => {
        if (result) {
          const previousGroup = this.processGroups.find(group => group.processGroupId === process.processGroupId);
          if (previousGroup) {
            previousGroup.processes = previousGroup.processes.filter(i => i.processId !== process.processId);
          }

          const newGroup = this.processGroups.find(group => group.processGroupId === result.processGroupId);
          if (newGroup) {
            newGroup.processes.push(result);
          }

          this.refreshProcessGroupTableData();
          this.refreshProcessTableData();
        }
      }
    });
  }

  public selectProcessGroup(processGroup: ProcessGroupModel) {
    if (this.selectedProcessGroup != processGroup) {
      this.selectedProcessGroup = processGroup;
      this.refreshProcessTableData();
    }
  }

  public createProcess() {
    if (!this.canEdit()) {
      return;
    }

    if (this.selectedProcessGroup) {
      this.router.navigateByUrl(`/${this.authService.currentUser!.companyName}/processes/${this.selectedProcessGroup.processGroupId}/0`);
    }
  }

  public editProcess(process: ProcessModel) {
    this.router.navigateByUrl(`/${this.authService.currentUser!.companyName}/processes/${process.processGroupId}/${process.processId}`);
  }

  public deleteProcess(process: ProcessModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Process "${process.title}"?`)) {
      return;
    }

    this.processService.deleteProcess(process.processId).subscribe({
      next: () => {
        const processGroup = this.processGroups.find(i => i.processGroupId == process.processGroupId);
        if (processGroup) {
          processGroup.processes.splice(processGroup.processes.indexOf(process), 1);
          this.refreshProcessTableData();
          this.toastr.success(`Process ${process.title} deleted`);
        }
      }
    });
  }

  private refreshProcessGroupTableData(): void {
    this.processGroupTableData.data = this.processGroups;
  }

  private refreshProcessTableData(): void {
    if (this.selectedProcessGroup) {
      this.processTableData.data = this.selectedProcessGroup.processes;
    } else {
      this.processGroupTableData.data = [];
    }
  }
}
