import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { StandardService } from '../../services/standard.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { StandardModel } from '../../models/standard-model';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow } from '@angular/material/table';
import { StandardDialogComponent } from './standard-dialog/standard-dialog.component';
import { StandardSectionModel } from '../../models/standard-section-model';
import { MatTableUtility } from '../../utility/mat-table-utility';
import { StandardSectionDialogComponent } from './standard-section-dialog/standard-section-dialog.component';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { NgIf, NgClass } from '@angular/common';
import { LoadingSpinnerComponent } from '../../shared/loading-spinner/loading-spinner.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { StandardSwapDialogComponent } from './standard-swap-dialog/standard-swap-dialog.component';

@Component({
    selector: 'app-standard-list',
    templateUrl: './standard-list.component.html',
    styleUrls: ['./standard-list.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatCard, MatCardContent, MatButton, RouterLink, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, NgClass, MatIconButton, MatTooltip, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow]
})
export class StandardListComponent implements OnInit {
  private route = inject(ActivatedRoute);

  public standards: Array<StandardModel>;
  public isLoading: boolean;
  public selectedStandard: StandardModel | null = null;

  public standardTableData = new MatTableDataSource<StandardModel>([]);
  public standardColumns: string[] = ['title', 'controls'];

  public standardSectionTableData = new MatTableDataSource<StandardSectionModel>([]);
  public standardSectionColumns: string[] = ['title', 'controls'];

  private standardSort: MatSort;
  private standardSectionSort: MatSort;

  @ViewChild('standardTable', { read: MatSort, static: false }) set standardSortValue(value: MatSort) {
    if (value) {
      this.standardSort = value;
      this.standardTableData.sort = this.standardSort;
    }
  };

  @ViewChild('standardSectionTable', { read: MatSort, static: false }) set standardSectionSortValue(value: MatSort) {
    if (value) {
      this.standardSectionSort = value;
      this.standardSectionTableData.sort = this.standardSectionSort;
    }
  };

  constructor(private standardService: StandardService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public authService: AuthService) {
  }

  public ngOnInit(): void {
    let groupIdParam = Number(this.route.snapshot.paramMap.get("groupId"));
    let sectionIdParam = Number(this.route.snapshot.paramMap.get("standardId"));

    this.isLoading = true;

    this.standardTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;
    this.standardSectionTableData.sortingDataAccessor = MatTableUtility.customSortingDataAccessor;

    this.standardService.getStandards().subscribe({
      next: (response: Array<StandardModel>) => {
        this.standards = response;
        this.refreshStandardTableData();

        if (groupIdParam != 0 && sectionIdParam != 0) {
          const standardGroup = this.standards.find(group => group.standardId === groupIdParam);
          const standardSection = standardGroup?.standardSections.find(standard => standard.standardSectionId == sectionIdParam);
          if (standardGroup && standardSection) {
            this.selectedStandard = standardGroup;
            this.refreshStandardSectionTableData();
            this.editStandardSection(standardSection);
          }
        } else if (groupIdParam != 0 && sectionIdParam == 0) {
          const standardGroup = this.standards.find(standard => standard.standardId === groupIdParam);
          if (standardGroup) {
            this.selectedStandard = standardGroup
            this.refreshStandardSectionTableData();
            this.editStandard(standardGroup);
          }
        }

        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public canEdit(): boolean {
    return this.authService.canCurrentUserEdit;
  }

  public singleGroup(): boolean {
    return this.standards.length <= 1;
  }

  public selectStandard(standard: StandardModel) {
    if (this.selectedStandard != standard) {
      this.selectedStandard = standard;
      this.refreshStandardSectionTableData();
    }
  }

  public createStandard() {
    if (!this.canEdit()) {
      return;
    }

    const standard = new StandardModel();

    const dialogConfig: MatDialogConfig = {
      data: {
        ...standard,
      }
    };

    const dialogRef = this.dialog.open(StandardDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: StandardModel) => {
        if (result) {
          this.standards.push(result);
          this.selectedStandard = result;
          this.refreshStandardTableData();
          this.refreshStandardSectionTableData();
        }
      }
    });
  }

  public editStandard(standard: StandardModel) {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...standard,
      },
      autoFocus: this.canEdit(),
    };

    const dialogRef = this.dialog.open(StandardDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: StandardModel) => {
        if (result) {
          const index = this.standards.findIndex(i => i.standardId == result.standardId);
          this.standards[index] = result;
          this.refreshStandardTableData();
        }
      }
    });
  }

  public deleteStandard(standard: StandardModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Standard "${standard.title}"?`)) {
      return;
    }

    this.standardService.deleteStandard(standard.standardId).subscribe({
      next: () => {
        this.standards.splice(this.standards.indexOf(standard), 1);
        this.refreshStandardTableData();
        this.toastr.success(`Standard "${standard.title}" deleted`);
      }
    });
  }

  public swapStandard(section: StandardSectionModel) {
    if (!this.canEdit() || !this.selectedStandard) {
      return;
    }

    const dialogConfig: MatDialogConfig = {
      data: {
        ...section
      },
      width: '60%'
    };

    const dialogRef = this.dialog.open(StandardSwapDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: StandardSectionModel) => {
        if (result) {
          const previousStandard = this.standards.find(standard => standard.standardId === section.standardId);
          if (previousStandard) {
            previousStandard.standardSections = previousStandard.standardSections.filter(i => i.standardSectionId !== section.standardSectionId)
          }

          const newStandard = this.standards.find(standard => standard.standardId === result.standardId);
          if (newStandard) {
            newStandard.standardSections.push(result);
          }

          this.refreshStandardSectionTableData();
          this.refreshStandardTableData();
        }
      }
    });
  }

  public createStandardSection() {
    if (!this.canEdit()) {
      return;
    }

    if (!this.selectedStandard) {
      return;
    }

    const standardSection = new StandardSectionModel();
    standardSection.standardId = this.selectedStandard.standardId;

    const dialogConfig: MatDialogConfig = {
      data: {
        ...standardSection,
      }
    };

    const dialogRef = this.dialog.open(StandardSectionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: StandardSectionModel) => {
        if (result) {
          this.selectedStandard!.standardSections.push(result);
          this.refreshStandardSectionTableData();
        }
      }
    });
  }

  public editStandardSection(standardSection: StandardSectionModel) {
    const dialogConfig: MatDialogConfig = {
      data: {
        ...standardSection,
      }
    };

    const dialogRef = this.dialog.open(StandardSectionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: StandardSectionModel) => {
        if (result) {
          const standard = this.standards.find(i => i.standardId == result.standardId);
          if (standard) {
            const index = standard.standardSections.findIndex(i => i.standardSectionId == result.standardSectionId);
            standard.standardSections[index] = result;
            this.refreshStandardSectionTableData();
          }
        }
      }
    });
  }

  public deleteStandardSection(standardSection: StandardSectionModel) {
    if (!this.canEdit()) {
      return;
    }

    if (!confirm(`Are you sure you want to delete Section "${standardSection.title}"?`)) {
      return;
    }

    this.standardService.deleteStandardSection(standardSection.standardSectionId).subscribe({
      next: () => {
        const standard = this.standards.find(i => i.standardId == standardSection.standardId);
        if (standard) {
          standard.standardSections.splice(standard.standardSections.indexOf(standardSection), 1);
          this.refreshStandardSectionTableData();
          this.toastr.success(`Section "${standardSection.title}" deleted`);
        }
      }
    });
  }

  private refreshStandardTableData(): void {
    this.standardTableData.data = this.standards;
  }

  private refreshStandardSectionTableData(): void {
    if (this.selectedStandard) {
      this.standardSectionTableData.data = this.selectedStandard.standardSections;
    } else {
      this.standardSectionTableData.data = [];
    }
  }
}
