import { Component, Inject, KeyValueDiffer, KeyValueDiffers } from "@angular/core";
import { ProcessAuditTaskNonConformanceModel } from "../../../../models/process-audit-task-non-conformance-model";
import { UserModel } from "../../../../models/user-model";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../../../services/auth.service";
import { NgForm, FormsModule } from "@angular/forms";
import { ValidationService } from "../../../../services/validation-service";
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow } from "@angular/material/table";
import { ProcessAuditTaskNonConformanceActionModel } from "../../../../models/process-audit-task-non-conformance-action-model";
import { ProcessAuditTaskNonConformanceActionDialogComponent } from "../process-audit-task-non-conformance-action-dialog/process-audit-task-non-conformance-action-dialog.component";
import { ProcessAuditStatusConstant } from "../../../../constants/process-audit-status-constant";
import { ProcessAuditTaskNonConformanceService } from "../../../../services/process-audit-task-non-conformance.service";
import { ProcessAuditTaskNonConformanceTypeConstant } from "../../../../constants/process-audit-task-non-conformance-type-constant";
import { ProcessAuditTaskNonConformanceRejectDialogComponent } from "../process-audit-task-non-conformance-reject-dialog/process-audit-task-non-conformance-reject-dialog.component";
import { NgIf, DatePipe } from "@angular/common";
import { LoadingSpinnerComponent } from "../../../../shared/loading-spinner/loading-spinner.component";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { MatTabGroup, MatTab } from "@angular/material/tabs";
import { MatFormField, MatLabel, MatError, MatHint, MatSuffix } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { UserSelectorComponent } from "../../../../shared/user-selector/user-selector.component";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIcon } from "@angular/material/icon";
import { ProcessAuditTaskNonConformanceClosedDialogComponent } from "../process-audit-task-non-conformance-closed-dialog/process-audit-task-non-conformance-closed-dialog.component";
import { DatePickerComponent } from "../../../../shared/date-picker/date-picker.component";
import { TruncatePipe } from "../../../../utility/truncate-pipe";

@Component({
    selector: 'app-process-audit-task-non-conformance-dialog',
    templateUrl: './process-audit-task-non-conformance-dialog.component.html',
    styleUrls: ['./process-audit-task-non-conformance-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule,
      MatTabGroup, MatTab, MatFormField, MatLabel, MatInput, MatError, UserSelectorComponent, MatSuffix,
      MatButton, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatIconButton,
      MatTooltip, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow, MatDialogActions,
      DatePipe, DatePickerComponent, TruncatePipe]
})

export class ProcessAuditTaskNonConformanceDialogComponent {
  private modelDiffer: KeyValueDiffer<string, any>;

  public model: ProcessAuditTaskNonConformanceModel;
  public dialogTitle = "";

  public users: Array<UserModel> = [];

  public isLoading: boolean = false;
  public isBusy: boolean = false;

  public processAuditNonConformanceActions: Array<ProcessAuditTaskNonConformanceActionModel> = [];
  public processAuditNonConformanceActionTableData = new MatTableDataSource<ProcessAuditTaskNonConformanceActionModel>([]);
  public processAuditNonConformanceActionColumns: string[] = ['reference', 'description', 'assignedTo', 'dueDate', 'status', 'controls'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public processAuditNonConformance: ProcessAuditTaskNonConformanceModel,
    private dialogRef: MatDialogRef<ProcessAuditTaskNonConformanceDialogComponent>,
    private processAuditTaskNonConformanceService: ProcessAuditTaskNonConformanceService,
    private toastr: ToastrService,
    public authService: AuthService,
    public validationService: ValidationService,
    private dialog: MatDialog,
    private differs: KeyValueDiffers) {
    this.receiveProcessAuditTaskNonConformance(processAuditNonConformance);

    if (this.isNew) {
      this.model.status = ProcessAuditStatusConstant.Open;
      this.dialogTitle = `New ${this.model.typeDescription}`;
    } else {
      this.dialogTitle = this.model.reference!;
    }
  }

  public ngOnInit(): void {
    if (!this.isNew) {
      this.processAuditTaskNonConformanceService.getNonConformanceActionsByNonConformanceId(this.model.processAuditTaskNonConformanceId).subscribe({
        next: (result: ProcessAuditTaskNonConformanceActionModel[]) => {
          this.processAuditNonConformanceActions = result;
          this.refreshProcessAuditNonConformanceActionTableData();
        }
      })
    }
  }

  public get isNew(): boolean {
    return this.model == null ||
      this.model.processAuditTaskNonConformanceId == null ||
      this.model.processAuditTaskNonConformanceId == undefined ||
      this.model.processAuditTaskNonConformanceId == 0;
  }

  public get canEdit(): boolean {
    return this.authService.canCurrentUserAudit;
  }

  public get isOpen(): boolean {
    return this.model.status === ProcessAuditStatusConstant.Open;
  }

  public get processAuditNonConformanceActionTitle(): string {
    return `Actions (${this.processAuditNonConformanceActionTableData.data.length})`;
  }

  public get isNonConformance(): boolean {
    return this.model.type === ProcessAuditTaskNonConformanceTypeConstant.NonConformance;
  }

  public isClosed() {
    return this.processAuditNonConformance.status === ProcessAuditStatusConstant.Closed;
  }

  public canSubmitForClosure(): boolean {
    return this.model.closureApproverUser?.userId != null &&
      this.model.status === ProcessAuditStatusConstant.Open &&
      (this.canEdit ||
      this.authService.currentUser?.userId == this.model.reportedByUser?.userId);
  }

  public canClose(): boolean {
    return this.model.status === ProcessAuditStatusConstant.PendingClosure &&
      (this.authService.currentUser?.isAdministrator ||
        this.authService.currentUser?.isCompanyAdministrator ||
        this.authService.currentUser?.userId == this.model.closureApproverUser?.userId);
  }

  public getStatusDescription(statusCode: string): string {
    const status = ProcessAuditStatusConstant.ValuesWithDescriptions.find(i => i.value === statusCode);
    return status ? status.description : statusCode;
  }

  public save(form: NgForm): void {
    if (!this.authService.canCurrentUserEdit) {
      return;
    }

    if (this.validationService.isFormValid(form)) {
      this.isBusy = true;

      this.processAuditTaskNonConformanceService.saveProcessAuditTaskNonConformance(this.model).subscribe({
        next: (response: ProcessAuditTaskNonConformanceModel) => {
          this.receiveProcessAuditTaskNonConformance(response);
          this.toastr.success(`${this.model.typeDescription} saved`);
          this.isBusy = false;
          this.dialogRef.close(this.model);
        }, error: () => {
          this.isBusy = false;
        }
      });
    }
  }

  public trySubmitForClosure() {
    if (!confirm(`Are you sure you want to submit this ${this.model.typeDescription} for closure? Changes cannot be made once submitted`)) {
      return;
    }

    if (this.modelDiffer.diff(this.model)) {
      this.processAuditTaskNonConformanceService.saveProcessAuditTaskNonConformance(this.model).subscribe({
        next: (response: ProcessAuditTaskNonConformanceModel) => {
          this.receiveProcessAuditTaskNonConformance(response);
          this.submitForClosure();
        }
      });
    } else {
      this.submitForClosure();
    }
  }

  private submitForClosure() {
    this.processAuditTaskNonConformanceService.submitNonConformanceForClosure(this.model.processAuditTaskNonConformanceId).subscribe({
      next: () => {
        this.model.status = ProcessAuditStatusConstant.PendingClosure;
        this.toastr.success(`${this.model.typeDescription} submitted for closure`);
        this.dialogRef.close(this.model);
      }
    });
  }

  public rejectNonConformance() {
    const dialogRef = this.dialog.open(ProcessAuditTaskNonConformanceRejectDialogComponent, {
      data: this.processAuditNonConformance
    });

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessAuditTaskNonConformanceModel) => {
        if (result) {
          this.model = result;
          this.dialogRef.close(this.model);
        }
      }
    });
  }

  public closeNonConformance() {
    const dialogRef = this.dialog.open(ProcessAuditTaskNonConformanceClosedDialogComponent, {
      data: this.processAuditNonConformance
    });

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessAuditTaskNonConformanceModel) => {
        if (result) {
          this.model = result;
          this.dialogRef.close(this.model);
        }
      }
    })
  }

  public addAction() {
    if (!this.authService.canCurrentUserEdit || this.isBusy) {
      return;
    }

    let processAuditNonConformanceAction = new ProcessAuditTaskNonConformanceActionModel();
    processAuditNonConformanceAction.processAuditTaskNonConformanceId = this.model.processAuditTaskNonConformanceId;

    const dialogConfig: MatDialogConfig = {
      data: processAuditNonConformanceAction
    };

    const dialogRef = this.dialog.open(ProcessAuditTaskNonConformanceActionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessAuditTaskNonConformanceActionModel) => {
        if (result) {
          this.processAuditNonConformanceActions.push(result);
          this.refreshProcessAuditNonConformanceActionTableData();
        }
      }
    });
  }

  public editProcessAuditNonConformanceAction(processAuditNonConformanceAction: ProcessAuditTaskNonConformanceActionModel) {
    const dialogConfig: MatDialogConfig = {
      data: processAuditNonConformanceAction
    };

    const dialogRef = this.dialog.open(ProcessAuditTaskNonConformanceActionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessAuditTaskNonConformanceActionModel) => {
        if (result) {
          const index = this.processAuditNonConformanceActions.findIndex(i => i.processAuditTaskNonConformanceActionId === result.processAuditTaskNonConformanceActionId);
          this.processAuditNonConformanceActions[index] = result;
          this.refreshProcessAuditNonConformanceActionTableData();
        }
      }
    });
  }

  public deleteNonConformanceAction(nonConformanceAction: ProcessAuditTaskNonConformanceActionModel) {
    if (!confirm("Are you sure you want to delete this Action?")) {
      return;
    }

    this.processAuditTaskNonConformanceService.deleteNonConformanceAction(nonConformanceAction.processAuditTaskNonConformanceActionId).subscribe({
      next: () => {
        this.processAuditNonConformanceActions.splice(this.processAuditNonConformanceActions.indexOf(nonConformanceAction), 1);
        this.refreshProcessAuditNonConformanceActionTableData();
        this.toastr.success("Action deleted");
      }
    })
  }

  private receiveProcessAuditTaskNonConformance(model: ProcessAuditTaskNonConformanceModel): void {
    // TODO required to deepClone object with functions included
    this.model = Object.setPrototypeOf(structuredClone(model), Object.getPrototypeOf(model));
    this.modelDiffer = this.differs.find(this.model).create();
  }

  private refreshProcessAuditNonConformanceActionTableData(): void {
    this.processAuditNonConformanceActionTableData.data = this.processAuditNonConformanceActions;
  }

  public close() {
    this.dialogRef.close();
  }
}
