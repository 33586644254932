import { AutocompleteOptionModel } from "./autocomplete-option-model";
import { StandardSectionModel } from "./standard-section-model";

export class StandardModel extends AutocompleteOptionModel {
  public standardId: number;
  public companyId: number;
  public title: string;
  public description: string | null;
  public createdUtc: Date;
  public modifiedUtc: Date;

  public standardSections: Array<StandardSectionModel> = [];

  constructor(data?: any) {
    super();

    if (data) {
      this.id = data.standardId;
      this.displayValue = data.title

      Object.assign(this, data);
    }
  }
}
