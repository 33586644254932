import { AutocompleteOptionModel } from "./autocomplete-option-model";
import { ResourceModel } from "./resource-model";

export class ResourceGroupModel extends AutocompleteOptionModel {
  public resourceGroupId: number;
  public companyId: number;
  public title: string;
  public description: string | null;
  public createdUtc: Date;
  public modifiedUtc: Date;

  public resources: Array<ResourceModel> = [];
  constructor(data?: any) {
    super();
    if (data) {
      this.id = data.resourceGroupId;
      this.displayValue = data.title;
      Object.assign(this, data);
    }
  }
}
