import { NgIf } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { LoadingSpinnerComponent } from "../../../shared/loading-spinner/loading-spinner.component";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog";
import { AutocompleteComponent } from "../../../shared/autocomplete/autocomplete.component";
import { MatButton } from "@angular/material/button";
import { ProcessGroupModel } from "../../../models/process-group-model";
import { ProcessService } from "../../../services/process.service";
import { ProcessModel } from "../../../models/process-model";
import { FormsModule } from "@angular/forms";

@Component({
  selector: 'app-process-swap-group-dialog',
  templateUrl: './process-swap-group-dialog.component.html',
  styleUrls: ['./process-swap-group-dialog.component.scss'],
  standalone: true,
  imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, MatDialogContent, AutocompleteComponent, MatDialogActions, MatButton,
    FormsModule]
})
export class ProcessSwapGroupDialogComponent {
  public options: Array<ProcessGroupModel>;
  public selectedProcessGroupId: number | null = null;
  public isLoading: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public process: ProcessModel,
    private dialogRef: MatDialogRef<ProcessSwapGroupDialogComponent>,
    private processService: ProcessService) {
  }

  public ngOnInit() {
    this.processService.getProcessGroups().subscribe({
      next: (response: Array<ProcessGroupModel>) => {
        this.options = response.filter(group => group.processGroupId !== this.process.processGroupId);
        this.isLoading = false;
      }
    });
  }

  public confirmGroup() {
    if (this.selectedProcessGroupId) {
      this.processService.updateProcessGroup(this.process.processId, this.selectedProcessGroupId).subscribe({
        next: (response: ProcessModel) => {
          this.dialogRef.close(response);
        }
      })
    }
  }

  public close() {
    this.dialogRef.close();
  }
}
