<mat-form-field color="accent" *ngIf="!isDisabled">
  <mat-label>{{label}}</mat-label>
  <input type="text"
         #selection="ngModel"
         placeholder="{{placeholder}}"
         matInput
         [(ngModel)]="selectedOption"
         (ngModelChange)="applyFilter($event)"
         [matAutocomplete]="auto"
         [required]="isRequired"
         (blur)="onBlur()" />
  <mat-icon class="autocompleteArrow" matSuffix>arrow_drop_down</mat-icon>
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="optionSelected($event)" [displayWith]="displayProperty">
    <mat-option *ngFor="let option of filteredOptions" [value]="option">
      {{option.displayValue}}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="selection.invalid">
    {{ requiredMessage }}
  </mat-error>
</mat-form-field>

<mat-form-field color="accent" *ngIf="isDisabled">
  <mat-label>{{label}}</mat-label>
  <mat-select [disabled]="true"></mat-select>
</mat-form-field>
