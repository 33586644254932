import { NgIf } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { LoadingSpinnerComponent } from "../../../shared/loading-spinner/loading-spinner.component";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog";
import { AutocompleteComponent } from "../../../shared/autocomplete/autocomplete.component";
import { MatButton } from "@angular/material/button";
import { DocumentGroupModel } from "../../../models/document-group-model";
import { DocumentService } from "../../../services/document.service";
import { DocumentModel } from "../../../models/document-model";
import { FormsModule } from "@angular/forms";

@Component({
  selector: 'app-document-swap-group-dialog',
  templateUrl: './document-swap-group-dialog.component.html',
  styleUrls: ['./document-swap-group-dialog.component.scss'],
  standalone: true,
  imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, MatDialogContent, AutocompleteComponent, MatDialogActions, MatButton,
    FormsModule]
})
export class DocumentSwapGroupDialogComponent {
  public options: Array<DocumentGroupModel>;
  public selectedDocumentGroupId: number | null = null;
  public isLoading: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public document: DocumentModel,
    private dialogRef: MatDialogRef<DocumentSwapGroupDialogComponent>,
    private documentService: DocumentService) {
  }

  public ngOnInit() {
    this.documentService.getDocumentGroups().subscribe({
      next: (response: Array<DocumentGroupModel>) => {
        this.options = response.filter(group => group.documentGroupId !== this.document.documentGroupId);
        this.isLoading = false;
      }
    });
  }

  public confirmGroup() {
    if (this.selectedDocumentGroupId) {
      this.documentService.updateDocumentGroup(this.document.documentId, this.selectedDocumentGroupId).subscribe({
        next: (response: DocumentModel) => {
          this.dialogRef.close(response);
        }
      })
    }
  }

  public close() {
    this.dialogRef.close();
  }
}
