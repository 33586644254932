import { Component, Input, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { AutocompleteOptionModel } from '../../models/autocomplete-option-model';
import { NgIf, NgFor } from '@angular/common';
import { MatError, MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  standalone: true,
  imports: [
    NgIf, MatFormField, MatLabel, MatInput, FormsModule,
    MatAutocompleteTrigger, MatIcon, MatSuffix, MatAutocomplete,
    NgFor, MatOption, MatSelect, MatError
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ]
})
export class AutocompleteComponent implements OnInit, ControlValueAccessor {
  public filteredOptions: AutocompleteOptionModel[];
  public selectedOption: AutocompleteOptionModel | null;

  @Input() canBeNull: boolean = true;
  @Input() isDisabled: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() options: Array<AutocompleteOptionModel> = [];
  @Input() placeholder: string = "";
  @Input() label: string = "";
  @Input() requiredMessage: string = "Selection is required";

  public value: number | null = null;

  private nullOption: AutocompleteOptionModel = {
    id: null,
    displayValue: "None"
  };

  private isInitialised: boolean = false;

  private onChange: (value: number | null) => void = () => { };
  private onTouched: () => void = () => { };

  public writeValue(value: number | null): void {
    this.value = value;

    if (value !== null) {
      this.selectedOption = this.options.find(option => option.id === value) || null;
    } else {
      this.selectedOption = null;
    }

    this.setOptions();
  }

  public registerOnChange(fn: (value: number | null) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngOnInit() {
    this.setOptions();
    this.isInitialised = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isInitialised) {
      return;
    }

    if (changes["value"] && this.value != this.selectedOption?.id) {
      if (!this.value) {
        this.selectedOption = null;
      } else {
        this.selectedOption = this.options.find(option => option.id === this.value) || null;
      }
    }
    this.setOptions();
  }

  private setOptions(): void {
    this.options = this.options.sort((a, b) =>
      (a.displayValue.toLowerCase() > b.displayValue.toLowerCase()) ? 1 : (b.displayValue.toLowerCase() > a.displayValue.toLowerCase()) ? -1 : 0);

    if (this.value) {
      let option = this.options.find(i => i.id == this.value);
      if (option) {
        this.selectedOption = option;
      }
    }

    this.filteredOptions = this.filter('');
  }

  public applyFilter(value: string) {
    this.filteredOptions = this.filter(value);
  }

  private filter(value: string): AutocompleteOptionModel[] {
    if (typeof value !== 'string') {
      if (this.canBeNull && !this.doesListIncludeNullOption()) {
        this.options.unshift(this.nullOption);
      }
      return this.options;
    }

    if (value == '' && this.canBeNull) {
      if (!this.doesListIncludeNullOption()) {
        this.options.unshift(this.nullOption);
      }
    } else if (this.doesListIncludeNullOption()) {
      this.options.splice(this.options.indexOf(this.nullOption), 1);
    }

    // Ensure null option is placed at the start of the list.
    if (this.canBeNull && this.doesListIncludeNullOption()) {
      let nullIndex = this.options.findIndex(i => i.id == null);
      if (nullIndex != 0) {
        this.options.unshift(this.options.splice(nullIndex, 1)[0]);
      }
    }

    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.displayValue.toLowerCase().includes(filterValue));
  }

  public optionSelected(e: MatAutocompleteSelectedEvent) {
    this.selectedOption = e.option.value;
    const value = this.selectedOption ? this.selectedOption.id : null;
    this.writeValue(value);
    this.onChange(value);
  }

  public displayProperty(value: AutocompleteOptionModel) {
    return value ? value.displayValue : '';
  }

  private doesListIncludeNullOption(): boolean {
    return this.options.some(i => i.id == null);
  }

  public onBlur() {
    this.onTouched();
  }
}
