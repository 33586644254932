<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <div class="row">
    <div class="col-sm-6">
      <mat-card appearance="outlined" class="list-card">
        <mat-card-content>
          <div class="list-button-row">
            <button *ngIf="canEdit()" mat-flat-button color="accent" class="button accent-button" (click)="createRoleGroup()">Add Role Group</button>
            <button mat-flat-button routerLink="/{{authService.currentUser!.companyName}}/roles-register" color="accent" class="button accent-button">Role Register</button>
          </div>

          <table #roleGroupTable mat-table [dataSource]="roleGroupTableData" matSort matSortActive="title" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
              <td mat-cell *matCellDef="let roleGroup">
                <button mat-flat-button
                        [ngClass]="{ 'row-highlight': selectedRoleGroup?.roleGroupId == roleGroup.roleGroupId }"
                        (click)="selectRoleGroup(roleGroup)"
                        class="button table-button">
                  {{roleGroup.title}}
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="controls">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let roleGroup" class="mat-cell-end">
                <button mat-icon-button
                        color="accent"
                        (click)="editRoleGroup(roleGroup)"
                        matTooltip="{{ canEdit() ? 'Edit' : 'View' }}">
                  <mat-icon>{{ canEdit() ? 'edit' : 'visibility' }}</mat-icon>
                </button>
                <button *ngIf="canEdit()"
                        mat-icon-button
                        color="accent"
                        (click)="deleteRoleGroup(roleGroup)"
                        matTooltip="Delete">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="roleGroupColumns"></tr>
            <tr mat-row
                [ngClass]="{ 'row-highlight': selectedRoleGroup?.roleGroupId == roleGroup.roleGroupId }"
                *matRowDef="let roleGroup; columns: roleGroupColumns;"></tr>
            <tr *matNoDataRow>
              <td [attr.colspan]="roleGroupColumns.length" class="no-data-row">
                No role groups found
              </td>
            </tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-sm-6">
      <mat-card appearance="outlined" class="list-card">
        <mat-card-content>
          <div class="list-button-row">
            <button *ngIf="canEdit()"
                    mat-flat-button
                    color="accent"
                    class="button accent-button"
                    [disabled]="selectedRoleGroup == null"
                    (click)="createRole()">Add Role</button>
          </div>

          <table #roleTable mat-table [dataSource]="roleTableData" matSort matSortActive="title" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
              <td mat-cell *matCellDef="let role">
                <button mat-flat-button
                        (click)="editRole(role)"
                        class="table-button">
                  {{role.title}}
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="controls">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let role" class="mat-cell-end">
                <button mat-icon-button
                        color="accent"
                        (click)="editRole(role)"
                        matTooltip="{{ canEdit() ? 'Edit' : 'View' }}">
                  <mat-icon>{{ canEdit() ? 'edit' : 'visibility' }}</mat-icon>
                </button>
                <button *ngIf="canEdit()"
                        mat-icon-button
                        color="accent"
                        (click)="deleteRole(role)"
                        matTooltip="Delete">
                  <mat-icon>delete</mat-icon>
                </button>
                <button *ngIf="canEdit() && !singleGroup()"
                        mat-icon-button
                        color="accent"
                        (click)="swapRoleGroup(role)"
                        matTooltip="Change Group">
                  <mat-icon>swap_horiz</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="roleColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: roleColumns;"></tr>
            <tr *matNoDataRow>
              <td [attr.colspan]="roleColumns.length" class="no-data-row">
                {{ selectedRoleGroup == null ? 'No group selected' : 'No roles found for the selected group' }}
              </td>
            </tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
