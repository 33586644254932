import { AutocompleteOptionModel } from "./autocomplete-option-model";
import { RoleModel } from "./role-model";

export class RoleGroupModel extends AutocompleteOptionModel {
  public roleGroupId: number;
  public companyId: number;
  public title: string;
  public description: string;
  public createdUtc: Date;
  public modifiedUtc: Date;

  public roles: Array<RoleModel> = [];

  constructor(data?: any) {
    super();

    if (data) {
      this.id = data.roleGroupId;
      this.displayValue = data.title

      Object.assign(this, data);
    }
  }
}
