<div class="row" *ngIf="!isLoading">
  <div class="col-sm-6">
    <mat-card appearance="outlined" class="list-card">
      <mat-card-content>
        <div class="row" style="margin-left:auto; margin-right:auto; max-width: fit-content;">
          <apx-chart [series]="auditsCompletePieChart.series"
                     [chart]="auditsCompletePieChart.chart"
                     [labels]="auditsCompletePieChart.labels"
                     [dataLabels]="auditsCompletePieChart.dataLabels"
                     [responsive]="auditsCompletePieChart.responsive"
                     [legend]="auditsCompletePieChart.legend"
                     [colors]="auditsCompletePieChart.colors"></apx-chart>
        </div>
        <div class="row">
          <apx-chart [series]="nonConformanceStatusChart.series"
                     [chart]="nonConformanceStatusChart.chart"
                     [dataLabels]="nonConformanceStatusChart.dataLabels"
                     [plotOptions]="nonConformanceStatusChart.plotOptions"
                     [xaxis]="nonConformanceStatusChart.xaxis"
                     [title]="nonConformanceStatusChart.title"></apx-chart>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-sm-6">
    <mat-card appearance="outlined" class="list-card">
      <mat-card-content>
        <div class="row">
          <apx-chart [series]="nonConformanceActionsUserChart.series"
                     [chart]="nonConformanceActionsUserChart.chart"
                     [dataLabels]="nonConformanceActionsUserChart.dataLabels"
                     [plotOptions]="nonConformanceActionsUserChart.plotOptions"
                     [xaxis]="nonConformanceActionsUserChart.xaxis"
                     [stroke]="nonConformanceActionsUserChart.stroke"
                     [fill]="nonConformanceActionsUserChart.fill"
                     [yaxis]="nonConformanceActionsUserChart.yaxis"
                     [title]="nonConformanceActionsUserChart.title"
                     [tooltip]="nonConformanceActionsUserChart.tooltip"
                     [legend]="nonConformanceActionsUserChart.legend"></apx-chart>
        </div>
      </mat-card-content>
      <div class="row">
      </div>
    </mat-card>
  </div>
</div>
