<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <div class="row">
    <div class="col-sm-12">
      <mat-card appearance="outlined" class="list-card">
        <mat-card-content>
          <div class="row">
            <div class="col-sm-12 search">
              <app-search-control [dataSource]="exceptionLogTableData"></app-search-control>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <table #exceptionLogTable mat-table [dataSource]="exceptionLogTableData" matSort matSortActive="loggedUtc" matSortDirection="desc" matSortDisableClear multiTemplateDataRows>
                <ng-container matColumnDef="loggedUtc">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Logged (UTC)</th>
                  <td mat-cell *matCellDef="let exceptionLog">
                    {{exceptionLog.loggedUtc | date: 'dd/MM/yy hh:mm:ss' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="endPoint">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>End Point</th>
                  <td mat-cell *matCellDef="let exceptionLog">
                    {{exceptionLog.endPoint}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="path">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Path</th>
                  <td mat-cell *matCellDef="let exceptionLog">
                    {{exceptionLog.path}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="message">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Message</th>
                  <td mat-cell *matCellDef="let exceptionLog">
                    {{exceptionLog.message}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="hasBeenReviewed">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Reviewed?</th>
                  <td mat-cell *matCellDef="let exceptionLog">
                    <mat-checkbox [(ngModel)]="exceptionLog.hasBeenReviewed"
                                  (ngModelChange)="hasBeenReviewedChanged(exceptionLog)"
                                  (click)="$event.stopPropagation()"></mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                  <td mat-cell *matCellDef="let exceptionLog" [attr.colspan]="exceptionLogColumns.length">
                    <div class="stack-trace-detail" *ngIf="expandedExceptionLog === exceptionLog">
                      <div class="stack-trace-description">
                        {{exceptionLog.stackTrace}}
                      </div>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="exceptionLogColumns"></tr>
                <tr mat-row *matRowDef="let exceptionLog; columns: exceptionLogColumns"
                    class="stack-trace-row"
                    (click)="expandedExceptionLog = expandedExceptionLog === exceptionLog ? null : exceptionLog">
                </tr>
                <tr mat-row *matRowDef="let exceptionLog; columns: ['expandedDetail']"
                    class="stack-trace-detail-row"
                    [class.expanded-row]="expandedExceptionLog === exceptionLog">
                </tr>
                <tr *matNoDataRow>
                  <td [attr.colspan]="exceptionLogColumns.length" class="no-data-row">
                    No data found
                  </td>
                </tr>
              </table>
              <mat-paginator [pageSizeOptions]="[50, 100, 200, 500]"
                             showFirstLastButtons
                             color="accent">
              </mat-paginator>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
