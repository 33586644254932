import { ChartDataModel } from "./chart-data-model";

export class ProcessAuditDashboardModel {
  public auditsPerYear: Array<ChartDataModel> = [];
  public openNonConformanceActionsByUser: Array<ChartDataModel> = [];
  public overdueNonConformanceActionsByUser: Array<ChartDataModel> = [];
  public closedNonConformanceActionsByUser: Array<ChartDataModel> = [];
  public openObservationActionsByUser: Array<ChartDataModel> = [];
  public overdueObservationActionsByUser: Array<ChartDataModel> = [];
  public closedObservationActionsByUser: Array<ChartDataModel> = [];
  public nonConformanceStatuses: Array<ChartDataModel> = [];
  public observationStatuses: Array<ChartDataModel> = [];

  constructor(data?: any) {
    if (data) {
      if (data.auditsPerYear) {
        this.auditsPerYear = data.auditsPerYear.map((i: any) => new ChartDataModel(i));
      }

      if (data.openNonConformanceActionsByUser) {
        this.openNonConformanceActionsByUser = data.openNonConformanceActionsByUser.map((i: any) => new ChartDataModel(i));
      }

      if (data.overdueNonConformanceActionsByUser) {
        this.overdueNonConformanceActionsByUser = data.overdueNonConformanceActionsByUser.map((i: any) => new ChartDataModel(i));
      }

      if (data.closedNonConformanceActionsByUser) {
        this.closedNonConformanceActionsByUser = data.closedNonConformanceActionsByUser.map((i: any) => new ChartDataModel(i));
      }

      if (data.openObservationActionsByUser) {
        this.openObservationActionsByUser = data.openObservationActionsByUser.map((i: any) => new ChartDataModel(i));
      }

      if (data.overdueObservationActionsByUser) {
        this.overdueObservationActionsByUser = data.overdueObservationActionsByUser.map((i: any) => new ChartDataModel(i));
      }

      if (data.closedObservationActionsByUser) {
        this.closedObservationActionsByUser = data.closedObservationActionsByUser.map((i: any) => new ChartDataModel(i));
      }

      if (data.nonConformanceStatuses) {
        this.nonConformanceStatuses = data.nonConformanceStatuses.map((i: any) => new ChartDataModel(i));
      }

      if (data.observationStatuses) {
        this.observationStatuses = data.observationStatuses.map((i: any) => new ChartDataModel(i));
      }

      Object.assign(this, data);
    }
  }
}
