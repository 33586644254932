<section class="dialog-container">
  <div>
    <h2 mat-dialog-title>Reject Audit</h2>
  </div>

  <mat-dialog-content>
    <mat-form-field color="accent">
      <mat-label>Rejection Comment</mat-label>
      <textarea matInput
                rows="5"
                placeholder="Enter Rejection Comment..."
                name="rejectionComment"
                [(ngModel)]="processAudit.rejectionComment"
                #rejectionComment="ngModel"
                required></textarea>
      <mat-error *ngIf="rejectionComment.invalid">
        Rejection Comment is required
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="button" mat-raised-button (click)="close()">Cancel</button>
    <button *ngIf="canReject()"
            mat-raised-button
            class="button red-button"
            (click)="rejectAudit()">
      Reject Audit
    </button>
  </mat-dialog-actions>
</section>
