<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <mat-card appearance="outlined" class="list-card">
    <mat-card-content>
      <div class="row">
        <div class="col-sm-12">
          <button mat-flat-button color="accent" class="button accent-button" (click)="export()" [disabled]="isBusy">Export</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 search">
          <app-search-control [dataSource]="standardRegisterTableData"></app-search-control>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <table #standardRegisterTable mat-table [dataSource]="standardRegisterTableData" matSort matSortActive="standardTitle" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="standard">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="standardTitle">Standard</th>
              <td mat-cell *matCellDef="let standardData">{{standardData.standardTitle}}</td>
            </ng-container>

            <ng-container matColumnDef="standardSection">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="standardSectionTitle">Standard Section</th>
              <td mat-cell *matCellDef="let standardData">{{standardData.standardSection}}</td>
            </ng-container>

            <ng-container matColumnDef="owner">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="owner">Owner</th>
              <td mat-cell *matCellDef="let standardData">{{standardData.owner}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="standardRegisterColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let standardData; columns: standardRegisterColumns;"></tr>
            <tr *matNoDataRow>
              <td [attr.colspan]="standardRegisterColumns.length">
                No data found
              </td>
            </tr>
          </table>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
