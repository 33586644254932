export class ProcessRevisionModel {
  public processRevisionId: number;
  public processId: number;
  public revisedDate: Date;
  public number: string ;
  public reason: string | null = null;
  public createdUtc: Date;
  public modifiedUtc: Date;

  constructor(data?: any) {
    if (data) {

      if (data.revisedDate) {
        data.revisedDate = new Date(data.revisedDate);
      }

      if (data.createdUtc) {
        data.createdUtc = new Date(data.createdUtc);
      }

      if (data.modifiedUtc) {
        data.modifiedUtc = new Date(data.modifiedUtc);
      }

      Object.assign(this, data);
    }
  }
}
