<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <mat-card appearance="outlined" class="list-card">
    <mat-card-content>
      <div class="row">
        <div class="col-sm-12">
          <button mat-flat-button color="accent" class="button accent-button" (click)="export()" [disabled]="isBusy">Export</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 search">
          <app-search-control [dataSource]="documentRegisterTableData"></app-search-control>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <table #documentRegisterTable mat-table [dataSource]="documentRegisterTableData" matSort matSortActive="documentGroupTitle" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="documentGroup">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="documentGroupTitle">Group</th>
              <td mat-cell *matCellDef="let documentData">{{ documentData.documentGroupTitle }}</td>
            </ng-container>

            <ng-container matColumnDef="document">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="documentIdentifier">Document</th>
              <td mat-cell *matCellDef="let documentData">
                <a *ngIf="documentData.link && !documentData.fileDocument" [href]="documentData.link" target="_blank">{{ documentData.documentIdentifier }}</a>
                <a *ngIf="!documentData.link && documentData.fileDocument" href="javascript:void(0)" (click)="downloadDocument(documentData)">{{ documentData.documentIdentifier }}</a>
                <span *ngIf="(documentData.link && documentData.fileDocument) || (!documentData.link && !documentData.fileDocument)">{{ documentData.documentIdentifier }}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="title">Title</th>
              <td mat-cell *matCellDef="let documentData">{{ documentData.title }}</td>
            </ng-container>

            <ng-container matColumnDef="version">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="version">Version</th>
              <td mat-cell *matCellDef="let documentData">{{ documentData.version }}</td>
            </ng-container>

            <ng-container matColumnDef="retention">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="retention">Retention</th>
              <td mat-cell *matCellDef="let documentData">{{ documentData.retention }}</td>
            </ng-container>

            <ng-container matColumnDef="reviewDueDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="reviewDueDate">Review Date</th>
              <td mat-cell *matCellDef="let documentData">{{ documentData.reviewDueDate | date: 'dd/MM/yyyy' }}</td>
            </ng-container>

            <ng-container matColumnDef="reviewFrequency">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="reviewFrequency">Review Freq.</th>
              <td mat-cell *matCellDef="let documentData">{{ documentData.reviewFrequency }}</td>
            </ng-container>

            <ng-container matColumnDef="owner">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="owner">Owner</th>
              <td mat-cell *matCellDef="let documentData">{{ documentData.owner }}</td>
            </ng-container>

            <ng-container matColumnDef="approver">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="approver">Approver</th>
              <td mat-cell *matCellDef="let documentData">{{ documentData.approver }}</td>
            </ng-container>

            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="description">Description</th>
              <td mat-cell *matCellDef="let documentData">{{ documentData.description }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="documentRegisterColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let documentData; columns: documentRegisterColumns;"></tr>
            <tr *matNoDataRow>
              <td [attr.colspan]="documentRegisterColumns.length" class="no-data-row">
                No documents found
              </td>
            </tr>
          </table>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
