import { Component, Inject } from "@angular/core";
import { ProcessAuditTaskNonConformanceActionModel } from "../../../../models/process-audit-task-non-conformance-action-model";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog";
import { ProcessAuditTaskNonConformanceClosedDialogComponent } from "../process-audit-task-non-conformance-closed-dialog/process-audit-task-non-conformance-closed-dialog.component";
import { ToastrService } from "ngx-toastr";
import { ProcessAuditTaskNonConformanceService } from "../../../../services/process-audit-task-non-conformance.service";
import { AuthService } from "../../../../services/auth.service";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatFormField, MatLabel, MatHint, MatSuffix, MatError } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { ProcessAuditStatusConstant } from "../../../../constants/process-audit-status-constant";

@Component({
  selector: 'app-process-audit-task-non-conformance-action-closed-dialog',
  templateUrl: './process-audit-task-non-conformance-action-closed-dialog.component.html',
  styleUrls: ['./process-audit-task-non-conformance-action-closed-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatFormField, MatLabel, MatInput, FormsModule, MatHint, MatSuffix, NgIf, MatError, MatDialogActions, MatButton]
})
export class ProcessAuditTaskNonConformanceActionClosedDialogComponent {
  public action: ProcessAuditTaskNonConformanceActionModel;

  constructor(@Inject(MAT_DIALOG_DATA) public model: ProcessAuditTaskNonConformanceActionModel,
    private dialogRef: MatDialogRef<ProcessAuditTaskNonConformanceClosedDialogComponent>,
    private toastr: ToastrService,
    private processAuditTaskNonConformanceService: ProcessAuditTaskNonConformanceService,
    public authService: AuthService) {
    this.action = structuredClone(model);
  }

  public canClose(): boolean {
    return this.model.status === ProcessAuditStatusConstant.PendingClosure &&
      (this.authService.currentUser?.isAdministrator ||
      this.authService.currentUser?.isCompanyAdministrator ||
      this.authService.currentUser?.userId == this.model.closureApproverUser?.userId)
  }

  public closeAction() {
    if (!this.action.closedComment) {
      this.toastr.error("Closed Comment is required.")
      return;
    } else if (!confirm(`Are you sure you want to close this Action?`)) {
      return;
    }

    this.processAuditTaskNonConformanceService.closeNonConformanceAction(this.action.processAuditTaskNonConformanceActionId, this.action.closedComment).subscribe({
      next: (response: ProcessAuditTaskNonConformanceActionModel) => {
        this.toastr.success("Action closed");
        this.dialogRef.close(response);
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
