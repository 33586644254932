import { NgIf } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { LoadingSpinnerComponent } from "../../../shared/loading-spinner/loading-spinner.component";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog";
import { AutocompleteComponent } from "../../../shared/autocomplete/autocomplete.component";
import { MatButton } from "@angular/material/button";
import { StandardModel } from "../../../models/standard-model";
import { StandardService } from "../../../services/standard.service";
import { StandardSectionModel } from "../../../models/standard-section-model";
import { FormsModule } from "@angular/forms";

@Component({
  selector: 'app-standard-swap-dialog',
  templateUrl: './standard-swap-dialog.component.html',
  styleUrls: ['./standard-swap-dialog.component.scss'],
  standalone: true,
  imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, MatDialogContent, AutocompleteComponent, MatDialogActions, MatButton,
    FormsModule]
})
export class StandardSwapDialogComponent {
  public options: Array<StandardModel>;
  public selectedStandardId: number | null = null;
  public isLoading: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public section: StandardSectionModel,
    private dialogRef: MatDialogRef<StandardSwapDialogComponent>,
    private standardService: StandardService) {
  }

  public ngOnInit() {
    this.standardService.getStandards().subscribe({
      next: (response: Array<StandardModel>) => {
        this.options = response.filter(standard => standard.standardId !== this.section.standardId);
        this.isLoading = false;
      }
    });
  }

  public confirmStandard() {
    if (this.selectedStandardId) {
      this.standardService.updateStandard(this.section.standardSectionId, this.selectedStandardId).subscribe({
        next: (response: StandardSectionModel) => {
          this.dialogRef.close(response);
        }
      })
    }
  }

  public close() {
    this.dialogRef.close();
  }
}
