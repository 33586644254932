<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<mat-card *ngIf="!isLoading" appearance="outlined" class="list-card">
  <mat-tab-group color="accent" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="Audit">
      <mat-card-content>
        <div class="row">
          <div class="col-sm-12">
            <div class="readonly-section">
              <label class="readonly-label">
                Process
              </label>
              <div class="readonly-content">
                {{ processAudit.processName }}
              </div>
            </div>

            <div class="readonly-section">
              <label class="readonly-label">
                Description
              </label>
              <div class="readonly-content">
                {{processAudit.processDescription}}
              </div>
            </div>

            <div class="readonly-section">
              <label class="readonly-label">
                Clauses
              </label>
              <div class="readonly-content">
                {{processAudit.processClauses}}
              </div>
            </div>

            <div class="readonly-section">
              <label class="readonly-label">
                Audited By
              </label>
              <div class="readonly-content">
                {{ processAudit.auditedByUser.displayName }}
              </div>
            </div>

            <div class="readonly-section">
              <label class="readonly-label">
                Date
              </label>
              <div class="readonly-content">
                {{ processAudit.date | date: 'dd/MM/yyyy' }}
              </div>
            </div>

            <app-user-selector *ngIf="canEdit()"
                               name="closureApproverUser"
                               [label]="'Closure Approver'"
                               [placeholder]="'Select a User'"
                               [(ngModel)]="processAudit.closureApproverUser"
                               [isDisabled]="isBusy">
            </app-user-selector>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Closure Approver
              </label>
              <div class="readonly-content">
                {{ processAudit.closureApproverUser?.displayName }}
              </div>
            </div>

            <div class="readonly-section" *ngIf="hasRejectionComment() && !isClosed()">
              <label class="readonly-label">
                Rejection Comment
              </label>
              <div class="readonly-content">
                {{ processAudit.rejectionComment }}
              </div>
            </div>

            <div class="readonly-section" *ngIf="isClosed()">
              <label class="readonly-label">
                Closed Comment
              </label>
              <div class="readonly-content">
                {{ processAudit.closedComment }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <table #processAuditTaskTable mat-table [dataSource]="processAuditTaskTableData">
              <ng-container matColumnDef="sequenceNumber">
                <th mat-header-cell *matHeaderCellDef>Seq.</th>
                <td mat-cell *matCellDef="let task">{{ task.headerSequenceNumber }}.{{ task.subSequenceNumber }}</td>
              </ng-container>

              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef>Title</th>
                <td mat-cell *matCellDef="let task">{{ task.title }}</td>
              </ng-container>

              <ng-container matColumnDef="responsible">
                <th mat-header-cell *matHeaderCellDef>Responsible</th>
                <td mat-cell *matCellDef="let task">{{ task.responsibleRole }}</td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let task" [innerHtml]=task.description></td>
              </ng-container>

              <ng-container matColumnDef="controls">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let task">
                  <button mat-icon-button
                          color="accent"
                          [disabled]="isBusy"
                          matTooltip="Open Audit Task"
                          (click)="openProcessAuditTaskDialog(task)">
                    <mat-icon>open_in_new</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="processAuditColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let task; columns: processAuditColumns;"></tr>
              <tr *matNoDataRow>
                <td [attr.colspan]="processAuditColumns.length" class="no-data-row">
                  No data found
                </td>
              </tr>
            </table>
            <div class="button-container" align="right">
              <div class="col-sm-6">
                <button *ngIf="canSubmitForClosure()"
                        mat-raised-button
                        [disabled]="isBusy"
                        class="button"
                        (click)="trySubmitForClosure()">
                  Submit for Closure
                </button>

                <button *ngIf="canClose()"
                        mat-raised-button
                        [disabled]="isBusy"
                        class="button red-button"
                        (click)="rejectClosure()">
                  Reject Closure
                </button>

                <button *ngIf="canClose()"
                        [disabled]="isBusy"
                        mat-raised-button
                        class="button green-button"
                        (click)="closeAudit()">
                  Close Audit
                </button>

                <button *ngIf="canEdit()"
                        [disabled]="isBusy"
                        mat-raised-button
                        color="accent"
                        class="button accent-button"
                        (click)="save()">
                  Save Audit
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-tab>

    <mat-tab label="Non Conformances & Observations">
      <mat-card-content>
        <div *ngIf="isRefreshingTableData" class="table-loading-spinner-container">
          <app-loading-spinner></app-loading-spinner>
        </div>
        <div *ngIf="!isRefreshingTableData" class="row">
          <div class="col-sm-12">
            <table #processAuditTaskNonConformanceTable mat-table [dataSource]="processAuditTaskNonConformanceTableData" matSort matSortActive="reference" matSortDirection="asc" matSortDisableClear>
              <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="reference">Reference</th>
                <td mat-cell *matCellDef="let nonConformance">
                  <button type="button"
                          mat-button
                          class="button table-button"
                          (click)="editProcessAuditTaskNonConformance(nonConformance)">
                    {{nonConformance.reference}}
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="title">Title</th>
                <td mat-cell *matCellDef="let nonConformance">{{nonConformance.title}}</td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="typeDescription">Type</th>
                <td mat-cell *matCellDef="let nonConformance">{{nonConformance.typeDescription}}</td>
              </ng-container>

              <ng-container matColumnDef="reportedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="reportedByUser.displayName">Reported By</th>
                <td mat-cell *matCellDef="let nonConformance">{{nonConformance.reportedByUser.displayName}}</td>
              </ng-container>

              <ng-container matColumnDef="reportedDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="reportedDate">Reported Date</th>
                <td mat-cell *matCellDef="let nonConformance">{{nonConformance.reportedDate | date: 'dd/MM/yyyy'}}</td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
                <td mat-cell *matCellDef="let nonConformance">{{ getStatusDescription(nonConformance.status) }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="processAuditTaskNonConformanceColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let nonConformance; columns: processAuditTaskNonConformanceColumns;"></tr>
              <tr *matNoDataRow>
                <td [attr.colspan]="processAuditTaskNonConformanceColumns.length" class="no-data-row">
                  No data found
                </td>
              </tr>
            </table>
          </div>
        </div>
      </mat-card-content>
    </mat-tab>

    <mat-tab label="Actions">
      <mat-card-content>
        <div *ngIf="isRefreshingTableData" class="table-loading-spinner-container">
          <app-loading-spinner></app-loading-spinner>
        </div>
        <div *ngIf="!isRefreshingTableData" class="row">
          <div class="col-sm-12">
            <table #actionTable mat-table [dataSource]="actionTableData" matSort matSortActive="reference" matSortDirection="asc" matSortDisableClear>
              <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="reference">Reference</th>
                <td mat-cell *matCellDef="let action">
                  <button type="button"
                          mat-button
                          class="button table-button"
                          (click)="editProcessAuditTaskNonConformanceAction(action)">
                    {{action.reference}}
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="description">Description</th>
                <td mat-cell *matCellDef="let action">{{action.description | truncate:[50, '...']}}</td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="processAuditTaskNonConformanceTypeDescription">Type</th>
                <td mat-cell *matCellDef="let action">{{action.processAuditTaskNonConformanceTypeDescription}}</td>
              </ng-container>

              <ng-container matColumnDef="assignedToUser">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="assignedToUser.displayName">Assigned To</th>
                <td mat-cell *matCellDef="let action">{{action.assignedToUser.displayName}}</td>
              </ng-container>

              <ng-container matColumnDef="dueDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="dueDate">Reported Date</th>
                <td mat-cell *matCellDef="let action">{{action.dueDate | date: 'dd/MM/yyyy'}}</td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
                <td mat-cell *matCellDef="let action">{{ getStatusDescription(action.status) }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="actionColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let action; columns: actionColumns;"></tr>
              <tr *matNoDataRow>
                <td [attr.colspan]="actionColumns.length" class="no-data-row">
                  No data found
                </td>
              </tr>
            </table>
          </div>
        </div>
      </mat-card-content>
    </mat-tab>
  </mat-tab-group>
</mat-card>
