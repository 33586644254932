<div class="container">
  <mat-toolbar color="primary">
    <div class="navbar-logo">
      <a routerLink="" class="nav-item initial-nav-item">
        <img src="{{ isTest ? 'assets/author-logo-test.png' : 'assets/author-logo.png' }}" alt="author-logo" width="71" height="38">
      </a>
    </div>
    <div *ngIf="authService.currentUser && authService.hasUserCompanyBeenSelected">
      <a mat-button routerLink="{{authService.currentUser.companyName}}" routerLinkActive="nav-active-item" [routerLinkActiveOptions]="{ exact: true }" class="nav-item">Home</a>
      <a mat-button routerLink="{{authService.currentUser.companyName}}/processes" routerLinkActive="nav-active-item" class="nav-item">Processes</a>
      <a mat-button routerLink="{{authService.currentUser.companyName}}/roles" routerLinkActive="nav-active-item" class="nav-item">Roles</a>
      <a mat-button routerLink="{{authService.currentUser.companyName}}/documents" routerLinkActive="nav-active-item" class="nav-item">Documents</a>
      <a mat-button routerLink="{{authService.currentUser.companyName}}/categories" routerLinkActive="nav-active-item" class="nav-item">Categories</a>
      <a mat-button routerLink="{{authService.currentUser.companyName}}/standards" routerLinkActive="nav-active-item" class="nav-item">Standards</a>
      <a mat-button routerLink="{{authService.currentUser.companyName}}/resources" routerLinkActive="nav-active-item" class="nav-item">Resources</a>
      <a mat-button *ngIf="authService.currentUser!.companyHasCustoms" routerLink="{{authService.currentUser!.companyName}}/customs" routerLinkActive="nav-active-item" class="nav-item">Customs</a>
      <a mat-button *ngIf="authService.currentUser && authService.currentUser.isCompanyAdministrator" routerLink="{{authService.currentUser!.companyName}}/admin" routerLinkActive="nav-active-item" class="nav-item">Admin</a>
      <a mat-button routerLink="/wiki" routerLinkActive="nav-active-item" class="nav-item">Guide</a>
      <a mat-button *ngIf="authService.currentUser!.companyCustomNav" [href]="authService.currentUser.companyCustomNavUrl" class="nav-item">{{authService.currentUser.companyCustomNav}}</a>
    </div>

    <span class="spacer"></span>

    <img *ngIf="authService.currentUser && authService.hasUserCompanyBeenSelected && this.authService.currentUser.companyIsLogoShownOnNavbar && blobUrl" src="{{blobUrl}}" alt="company-logo" class="nav-company-logo">
    <a mat-button [matMenuTriggerFor]="menu" class="nav-item final-nav-item">
      <mat-icon class="settings-icon">menu</mat-icon>
    </a>
    <mat-menu #menu="matMenu">
      <span mat-menu-item *ngIf="authService.currentUser">
        {{authService.currentUser!.displayName}} : {{authService.currentUser!.companyDisplayName}}
      </span>
      <mat-divider *ngIf="authService.currentUser && authService.currentUser!.isAdministrator"></mat-divider>
      <a *ngIf="authService.currentUser && authService.hasMultipleCompanies()" mat-menu-item routerLink="company-select">Change Company</a>
      <a *ngIf="authService.currentUser && !isUserUsingSingleSignOn" mat-menu-item routerLink="change-password">Change Password</a>
      <mat-divider *ngIf="authService.currentUser"></mat-divider>
      <a mat-menu-item href="https://authorprocesses.com/system-updates/" target="_blank">System Updates</a>
      <a mat-menu-item href="mailto:info@prismenergy.co.uk" target="_blank">Need Help? Contact Us</a>
      <mat-divider *ngIf="authService.currentUser && authService.currentUser!.isAdministrator"></mat-divider>
      <a *ngIf="authService.currentUser && authService.currentUser!.isAdministrator" mat-menu-item routerLink="exception-log">Exception Log</a>
      <mat-divider *ngIf="authService.currentUser"></mat-divider>
      <button *ngIf="authService.currentUser" mat-menu-item (click)="signOut()">Sign Out</button>
    </mat-menu>
  </mat-toolbar>

  <div class="content-container">
    <router-outlet></router-outlet>
  </div>
</div>
