<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>{{ dialogTitle }}</h2>
  </div>

  <mat-dialog-content>
    <form id="standardSectionForm" #standardSectionForm="ngForm" (ngSubmit)="save(standardSectionForm)">
      <mat-tab-group color="accent">
        <mat-tab label="Details" [disabled]="isBusy">
          <div class="mat-tab-content-container dialog-mat-tab-content-container">
            <mat-form-field color="accent" *ngIf="canEdit()">
              <mat-label>Title</mat-label>
              <input matInput
                     placeholder="Enter title..."
                     name="title"
                     [(ngModel)]="model.title"
                     #title="ngModel"
                     [disabled]="isBusy"
                     required>
              <mat-error *ngIf="title.invalid">
                Title is required
              </mat-error>
            </mat-form-field>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Title
              </label>
              <div class="readonly-content">
                {{ model.title }}
              </div>
            </div>

            <mat-form-field color="accent" *ngIf="canEdit()">
              <mat-label>Description</mat-label>
              <textarea matInput
                        placeholder="Enter description..."
                        name="description"
                        [disabled]="isBusy"
                        [(ngModel)]="model.description"></textarea>
            </mat-form-field>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Description
              </label>
              <div class="readonly-content">
                {{ model.description }}
              </div>
            </div>

            <app-autocomplete name="ownerRole"
                              *ngIf="canEdit()"
                              [label]="'Owner'"
                              [placeholder]="'Select a Role'"
                              [(ngModel)]="model.ownerRoleId"
                              [options]="roles"
                              [isDisabled]="isBusy">
            </app-autocomplete>

            <div class="readonly-section" *ngIf="!canEdit()">
              <label class="readonly-label">
                Owner
              </label>
              <div class="readonly-content">
                {{ getRoleTitle(model.ownerRoleId) }}
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="{{ documentsTabTitle }}" [disabled]="isNew || isBusy">
          <div class="mat-tab-content-container dialog-mat-tab-content-container">
            <button *ngIf="canEdit()"
                    mat-flat-button
                    color="accent"
                    class="button accent-button"
                    type="button"
                    (click)="createStandardSectionDocument()">
              Add Document
            </button>

            <table #documentsTable mat-table [dataSource]="documentTableData" matSort matSortActive="documentName" matSortDirection="asc" matSortDisableClear>
              <ng-container matColumnDef="documentName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="documentName">Title</th>
                <td mat-cell *matCellDef="let document">
                  <a *ngIf="document.link && !document.fileDocument" [href]="document.link" target="_blank">{{ document.documentName }}</a>
                  <a *ngIf="!document.link && document.fileDocument" href="javascript:void(0)" (click)="downloadDocument(document)">{{ document.documentName }}</a>
                  <span *ngIf="(document.link && document.fileDocument) || (!document.link && !document.fileDocument)">{{ document.documentName }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="controls">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let document" class="mat-cell-end">
                  <button mat-icon-button
                          color="accent"
                          type="button"
                          (click)="deleteStandardSectionDocument(document)"
                          matTooltip="Delete">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="documentColumns"></tr>
              <tr mat-row
                  *matRowDef="let document; columns: documentColumns;"></tr>
            </table>
          </div>
        </mat-tab>

        <mat-tab label="{{processesTabTitle}}" [disabled]="isNew || isBusy">
          <div class="mat-tab-container dialog-mat-tab-content-container">
            <table #standardSectionProcessesTable mat-table [dataSource]="processesTableData" matSort matSortActive="title" matSortDirection="asc" matSortDisableClear>
              <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
                <td mat-cell *matCellDef="let process">
                  <button mat-flat-button type="button" (click)="openProcess(process)">
                    {{process.referenceAndTitle}}
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="processesColumns"></tr>
              <tr mat-row *matRowDef="let process; columns: processesColumns"></tr>
            </table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="button" (click)="close()" [disabled]="isBusy">Close</button>
    <button *ngIf="!isNew" mat-raised-button color="accent" class="button accent-button" (click)="share()" [disabled]="isBusy">Share</button>
    <button type="submit" *ngIf="canEdit()" mat-raised-button color="accent" class="button accent-button" form="standardSectionForm" [disabled]="isBusy">Save</button>
  </mat-dialog-actions>
</section>

