<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>{{ dialogTitle }}</h2>
  </div>

  <mat-dialog-content>
    <form id="processAuditNonConformanceForm" #processAuditNonConformanceForm="ngForm" (ngSubmit)="save(processAuditNonConformanceForm)">
      <mat-tab-group color="accent">
        <mat-tab label="Details" [disabled]="isBusy">
          <div class="mat-tab-content-container dialog-mat-tab-content-container">
            <div *ngIf="!isNew" class="readonly-section">
              <label class="readonly-label">
                Reference
              </label>
              <div class="readonly-content">
                {{ model.reference }}
              </div>
            </div>

            @if ((isOpen || isNew) && canEdit) {
            <mat-form-field color="accent">
              <mat-label>Title</mat-label>
              <input matInput
                     placeholder="Enter Title..."
                     name="title"
                     [(ngModel)]="model.title"
                     #title="ngModel"
                     [disabled]="isBusy"
                     required>
              <mat-error *ngIf="title.invalid">
                Title is required
              </mat-error>
            </mat-form-field>

            <app-user-selector name="reportedByUser"
                               [label]="'Reported By'"
                               [placeholder]="'Select a User'"
                               [(ngModel)]="model.reportedByUser"
                               [isDisabled]="isBusy"
                               [isRequired]="true"
                               [requiredMessage]="'Reported By is required'">
            </app-user-selector>

            <app-date-picker name="reportedDate"
                             [label]="'Reported Date'"
                             [(ngModel)]="model.reportedDate"
                             [isDisabled]="isBusy"
                             [isRequired]="true"
                             [requiredMessage]="'Reported Date is required'"></app-date-picker>

            <mat-form-field color="accent">
              <mat-label>Description</mat-label>
              <textarea matInput
                        placeholder="Enter Description..."
                        name="description"
                        [(ngModel)]="model.description"
                        #description="ngModel"
                        [disabled]="isBusy"
                        required></textarea>
              <mat-error *ngIf="description.invalid">
                Description is required
              </mat-error>
            </mat-form-field>

            <mat-form-field color="accent" *ngIf="isNonConformance">
              <mat-label>Clause or Process</mat-label>
              <textarea matInput
                        placeholder="Enter clause or process..."
                        name="clause"
                        [(ngModel)]="model.clause"
                        #clause="ngModel"
                        [disabled]="isBusy"></textarea>
            </mat-form-field>

            <app-user-selector name="closureApproverUser"
                               [label]="'Closure Approver'"
                               [placeholder]="'Select a User'"
                               [(ngModel)]="model.closureApproverUser"
                               [isDisabled]="isBusy">
            </app-user-selector>
            } @else {
            <div class="readonly-section">
              <label class="readonly-label">
                Title
              </label>
              <div class="readonly-content">
                {{ model.title }}
              </div>
            </div>

            <div class="readonly-section">
              <label class="readonly-label">
                Reported by
              </label>
              <div class="readonly-content">
                {{ model.reportedByUser.displayName }}
              </div>
            </div>

            <div class="readonly-section">
              <label class="readonly-label">
                Reported Date
              </label>
              <div class="readonly-content">
                {{ model.reportedDate | date: 'dd/MM/yyyy' }}
              </div>
            </div>

            <div class="readonly-section">
              <label class="readonly-label">
                Description
              </label>
              <div class="readonly-content">
                {{ model.description }}
              </div>
            </div>

            <div class="readonly-section">
              <label class="readonly-label">
                Closure Approver
              </label>
              <div class="readonly-content">
                {{ model.closureApproverUser?.displayName }}
              </div>
            </div>

            <div class="readonly-section" *ngIf="isClosed()">
              <label class="readonly-label">
                Closed Comment
              </label>
              <div class="readonly-content">
                {{ model.closedComment }}
              </div>
            </div>
            }
          </div>
        </mat-tab>

        <mat-tab label="{{processAuditNonConformanceActionTitle}}" [disabled]="isNew || isBusy">
          <div class="mat-tab-content-container dialog-mat-tab-content-container">
            <button *ngIf="canEdit && isOpen"
                    type="button"
                    mat-flat-button
                    color="accent"
                    class="button accent-button"
                    (click)="addAction()">
              Add Action
            </button>

            <table #processAuditNonConformanceActionTable mat-table [dataSource]="processAuditNonConformanceActionTableData">
              <ng-container matColumnDef="reference">
                <th mat-header-cell *matHeaderCellDef>Reference</th>
                <td mat-cell *matCellDef="let action">
                  <button type="button"
                          mat-button
                          class="button table-button"
                          (click)="editProcessAuditNonConformanceAction(action)">
                    {{action.reference}}
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let action">{{action.description | truncate:[50, '...']}}</td>
              </ng-container>

              <ng-container matColumnDef="assignedTo">
                <th mat-header-cell *matHeaderCellDef>Assigned To</th>
                <td mat-cell *matCellDef="let action">{{action.assignedToUser.displayName}}</td>
              </ng-container>

              <ng-container matColumnDef="dueDate">
                <th mat-header-cell *matHeaderCellDef>Due Date</th>
                <td mat-cell *matCellDef="let action">{{action.dueDate | date: 'dd/MM/yyyy'}}</td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let action">{{getStatusDescription(action.status)}}</td>
              </ng-container>

              <ng-container matColumnDef="controls">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let action" class="mat-cell-end">
                  <button *ngIf="canEdit && isOpen"
                          type="button"
                          mat-icon-button
                          color="accent"
                          matTooltip="Delete"
                          (click)="deleteNonConformanceAction(action)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="processAuditNonConformanceActionColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let action; columns: processAuditNonConformanceActionColumns;"></tr>
              <tr *matNoDataRow>
                <td [attr.colspan]="processAuditNonConformanceActionColumns.length" class="no-data-row">
                  No data found
                </td>
              </tr>
            </table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="button"
            type="button"
            mat-raised-button
            (click)="close()"
            [disabled]="isBusy">
      Close
    </button>

    <button *ngIf="canSubmitForClosure() && !isNew"
            type="button"
            class="button"
            mat-raised-button
            [disabled]="isBusy"
            (click)="trySubmitForClosure()">
      Submit for Closure
    </button>

    <button *ngIf="canClose()"
            type="button"
            class="button red-button"
            mat-raised-button
            [disabled]="isBusy"
            (click)="rejectNonConformance()">
      Reject Closure
    </button>

    <button *ngIf="canClose()"
            type="button"
            class="button green-button"
            mat-raised-button
            [disabled]="isBusy"
            (click)="closeNonConformance()">
      Close {{ model.typeDescription }}
    </button>

    <button *ngIf="canEdit && isOpen"
            type="submit"
            mat-raised-button
            color="accent"
            class="button accent-button"
            form="processAuditNonConformanceForm"
            [disabled]="isBusy">
      Save
    </button>
  </mat-dialog-actions>
</section>
