import { Component, Input } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';
import { MatTableDataSource } from '@angular/material/table';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-search-control',
  standalone: true,
  imports: [MatFormField, MatInput],
  templateUrl: './search-control.component.html',
  styleUrl: './search-control.component.scss'
})
export class SearchControlComponent {
  @Input() public dataSource: MatTableDataSource<any>;

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
