<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<section class="dialog-container" *ngIf="!isLoading">
  <div>
    <h2 mat-dialog-title>Select Role Group</h2>
  </div>

  <mat-dialog-content>
    <app-autocomplete [label]="'Role Group'"
                      [placeholder]="'Select a Role Group'"
                      [(ngModel)]="selectedRoleGroupId"
                      [options]="options"
                      [canBeNull]="false">
    </app-autocomplete>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button class="button" (click)="close()">Cancel</button>
    <button mat-raised-button class="button accent=button" color="accent" (click)="confirmGroup()">Save</button>
  </mat-dialog-actions>
</section>
