import { NgIf } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { LoadingSpinnerComponent } from "../../../shared/loading-spinner/loading-spinner.component";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog";
import { AutocompleteComponent } from "../../../shared/autocomplete/autocomplete.component";
import { MatButton } from "@angular/material/button";
import { RoleGroupModel } from "../../../models/role-group-model";
import { RoleService } from "../../../services/role.service";
import { RoleModel } from "../../../models/role-model";
import { FormsModule } from "@angular/forms";

@Component({
  selector: 'app-role-swap-group-dialog',
  templateUrl: './role-swap-group-dialog.component.html',
  styleUrls: ['./role-swap-group-dialog.component.scss'],
  standalone: true,
  imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, MatDialogContent, AutocompleteComponent, MatDialogActions, MatButton,
    FormsModule]
})
export class RoleSwapGroupDialogComponent {
  public options: Array<RoleGroupModel>;
  public selectedRoleGroupId: number | null = null;
  public isLoading: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public role: RoleModel,
    private dialogRef: MatDialogRef<RoleSwapGroupDialogComponent>,
    private roleService: RoleService) {
  }

  public ngOnInit() {
    this.roleService.getRoleGroups().subscribe({
      next: (response: Array<RoleGroupModel>) => {
        this.options = response.filter(group => group.roleGroupId !== this.role.roleGroupId);
        this.isLoading = false;
      }
    });
  }

  public confirmGroup() {
    if (this.selectedRoleGroupId) {
      this.roleService.updateRoleGroup(this.role.roleId, this.selectedRoleGroupId).subscribe({
        next: (response: RoleModel) => {
          this.dialogRef.close(response);
        }
      });
    }
  }

  public close() {
    this.dialogRef.close();
  }
}
