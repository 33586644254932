import { NgIf } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { LoadingSpinnerComponent } from "../../../shared/loading-spinner/loading-spinner.component";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from "@angular/material/dialog";
import { MatButton } from "@angular/material/button";
import { ResourceGroupModel } from "../../../models/resource-group-model";
import { ResourceService } from "../../../services/resource.service";
import { ResourceModel } from "../../../models/resource-model";
import { AutocompleteComponent } from "../../../shared/autocomplete/autocomplete.component";
import { FormsModule } from "@angular/forms";

@Component({
  selector: 'app-resource-swap-group-dialog',
  templateUrl: './resource-swap-group-dialog.component.html',
  styleUrls: ['./resource-swap-group-dialog.component.scss'],
  standalone: true,
  imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, MatDialogContent, AutocompleteComponent, MatDialogActions, MatButton,
    FormsModule]
})
export class ResourceSwapGroupDialogComponent {
  public options: Array<ResourceGroupModel>;
  public selectedResourceGroupId: number | null = null;
  public isLoading: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public resource: ResourceModel,
    private dialogRef: MatDialogRef<ResourceSwapGroupDialogComponent>,
    private resourceService: ResourceService) {
  }

  public ngOnInit() {
    this.resourceService.getResourceGroups().subscribe({
      next: (response: Array<ResourceGroupModel>) => {
        this.options = response.filter(group => group.resourceGroupId !== this.resource.resourceGroupId);
        this.isLoading = false;
      }
    });
  }

  public confirmGroup() {
    if (this.selectedResourceGroupId) {
      this.resourceService.updateResourceGroup(this.resource.resourceId, this.selectedResourceGroupId).subscribe({
        next: (response: ResourceModel) => {
          this.dialogRef.close(response);
        }
      })
    }
  }

  public close() {
    this.dialogRef.close();
  }
}
