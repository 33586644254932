import { Component, Inject } from "@angular/core";
import { ProcessAuditTaskModel } from "../../../../models/process-audit-task-model";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { AuthService } from "../../../../services/auth.service";
import { ProcessAuditService } from "../../../../services/process-audit.service";
import { ToastrService } from "ngx-toastr";
import { ProcessAuditTaskNonConformanceModel } from "../../../../models/process-audit-task-non-conformance-model";
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow } from "@angular/material/table";
import { Observable, forkJoin } from "rxjs";
import { ProcessAuditTaskNonConformanceDialogComponent } from "../process-audit-task-non-conformance-dialog/process-audit-task-non-conformance-dialog.component";
import { ProcessAuditStatusConstant } from "../../../../constants/process-audit-status-constant";
import { ProcessAuditTaskNonConformanceService } from "../../../../services/process-audit-task-non-conformance.service";
import { ProcessAuditTaskNonConformanceTypeConstant } from "../../../../constants/process-audit-task-non-conformance-type-constant";
import { NgIf, DatePipe } from "@angular/common";
import { LoadingSpinnerComponent } from "../../../../shared/loading-spinner/loading-spinner.component";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { MatTabGroup, MatTab } from "@angular/material/tabs";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { MatIcon } from "@angular/material/icon";

@Component({
    selector: 'app-process-audit-task-dialog',
    templateUrl: './process-audit-task-dialog.component.html',
    styleUrls: ['./process-audit-task-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingSpinnerComponent, MatDialogTitle, CdkScrollable, MatDialogContent, MatTabGroup,
      MatTab, MatFormField, MatLabel, MatInput, FormsModule, MatButton, MatTable, MatColumnDef, MatHeaderCellDef,
      MatHeaderCell, MatCellDef, MatCell, MatIconButton, MatTooltip, MatIcon, MatHeaderRowDef, MatHeaderRow,
      MatRowDef, MatRow, MatNoDataRow, MatDialogActions, DatePipe]
})

export class ProcessAuditTaskDialogComponent {
  public processAuditTask: ProcessAuditTaskModel;
  public processAuditStatus: string;

  public isLoading: boolean = true;
  public isBusy: boolean = false;

  public processAuditTaskNonConformances: Array<ProcessAuditTaskNonConformanceModel> = [];
  public processAuditTaskNonConformanceTableData = new MatTableDataSource<ProcessAuditTaskNonConformanceModel>([]);
  public processAuditTaskNonConformanceColumns: string[] = ['title', 'reportedBy', 'reportedDate', 'description', 'status', 'controls'];

  public processAuditTaskObservations: Array<ProcessAuditTaskNonConformanceModel> = [];
  public processAuditTaskObservationTableData = new MatTableDataSource<ProcessAuditTaskNonConformanceModel>([]);
  public processAuditTaskObservationColumns: string[] = ['title', 'reportedBy', 'reportedDate', 'description', 'status', 'controls'];

  constructor(@Inject(MAT_DIALOG_DATA) public task: ProcessAuditTaskModel,
    private dialogRef: MatDialogRef<ProcessAuditTaskDialogComponent>,
    public authService: AuthService,
    private processAuditService: ProcessAuditService,
    private processAuditTaskNonConformanceService: ProcessAuditTaskNonConformanceService,
    private toastr: ToastrService,
    private dialog: MatDialog) {
    this.processAuditTask = structuredClone(task);
  }

  public ngOnInit(): void {
    let sources: Array<Observable<any>> = [
      this.processAuditTaskNonConformanceService.getProcessAuditTaskNonConformanceByProcessAuditTaskId(this.processAuditTask.processAuditTaskId),
      this.processAuditService.getProcessAuditStatusById(this.processAuditTask.processAuditId) // TODO is this needed?
    ]

    forkJoin(sources).subscribe({
      next: (response) => {
        this.processAuditTaskNonConformances = response[0] as ProcessAuditTaskNonConformanceModel[];
        this.processAuditTaskNonConformanceTableData.data =
          this.processAuditTaskNonConformances.filter(i => i.type === ProcessAuditTaskNonConformanceTypeConstant.NonConformance);
        this.processAuditTaskObservationTableData.data =
          this.processAuditTaskNonConformances.filter(i => i.type === ProcessAuditTaskNonConformanceTypeConstant.Observation);
        this.processAuditStatus = response[1] as string;
        this.isLoading = false;
      }
    })
  }

  public get nonConformanceCount(): number {
    return this.processAuditTaskNonConformances.filter(i => i.type === ProcessAuditTaskNonConformanceTypeConstant.NonConformance).length;
  }

  public get observationCount(): number {
    return this.processAuditTaskNonConformances.filter(i => i.type === ProcessAuditTaskNonConformanceTypeConstant.Observation).length;
  }

  public isProcessAuditOpen(): boolean {
    return this.processAuditStatus === ProcessAuditStatusConstant.Open;
  }

  public getStatusDescription(statusCode: string): string {
    const status = ProcessAuditStatusConstant.ValuesWithDescriptions.find(i => i.value === statusCode);
    return status ? status.description : statusCode;
  }

  public dialogTitle(): string {
    return `${this.processAuditTask.headerSequenceNumber}.${this.processAuditTask.subSequenceNumber} - ${this.processAuditTask.title}`
  }

  public save() {
    if (!this.authService.canCurrentUserAudit) {
      return;
    }

    this.processAuditService.saveProcessAuditTask(this.processAuditTask).subscribe({
      next: (response: ProcessAuditTaskModel) => {
        this.processAuditTask = response;
        this.isBusy = false;
        this.toastr.success("Task Saved");
        this.dialogRef.close(this.processAuditTask);
      }, error: () => {
        this.isBusy = false;
      }
    });
  }

  public addNonConformance(task: ProcessAuditTaskModel, isNonConformance: boolean): void {
    if (!this.authService.canCurrentUserAudit || this.isBusy) {
      return;
    }

    let processAuditTaskNonConformance = new ProcessAuditTaskNonConformanceModel();
    processAuditTaskNonConformance.processAuditTaskId = task.processAuditTaskId;
    processAuditTaskNonConformance.type = isNonConformance ?
      ProcessAuditTaskNonConformanceTypeConstant.NonConformance : ProcessAuditTaskNonConformanceTypeConstant.Observation;

    const dialogConfig: MatDialogConfig = {
      data: processAuditTaskNonConformance
    };

    const dialogRef = this.dialog.open(ProcessAuditTaskNonConformanceDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessAuditTaskNonConformanceModel) => {
        if (result) {
          this.processAuditTaskNonConformances.push(result);
          this.refreshProcessAuditTaskNonConformanceTableData();
        }
      }
    });
  }

  public editProcessAuditTaskNonConformance(processAuditTaskNonConformance: ProcessAuditTaskNonConformanceModel): void {
    if (this.isBusy) {
      return;
    }

    const dialogConfig: MatDialogConfig = {
      data: processAuditTaskNonConformance
    };

    const dialogRef = this.dialog.open(ProcessAuditTaskNonConformanceDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe({
      next: (result: ProcessAuditTaskNonConformanceModel) => {
        if (result) {
          const index = this.processAuditTaskNonConformances.findIndex(i => i.processAuditTaskNonConformanceId == result.processAuditTaskNonConformanceId);
          this.processAuditTaskNonConformances[index] = result;
          this.refreshProcessAuditTaskNonConformanceTableData();
        }
      }
    })
  }

  public deleteProcessAuditTaskNonConformance(processAuditTaskNonConformance: ProcessAuditTaskNonConformanceModel) {
    if (!confirm(`Are you sure you want to delete ${processAuditTaskNonConformance.typeDescription} this and any assoicated Actions?`)) {
      return
    }

    this.processAuditTaskNonConformanceService.deleteProcessAuditTaskNonConformance(processAuditTaskNonConformance.processAuditTaskNonConformanceId).subscribe({
      next: () => {
        this.processAuditTaskNonConformances.splice(this.processAuditTaskNonConformances.indexOf(processAuditTaskNonConformance), 1);
        this.refreshProcessAuditTaskNonConformanceTableData();
        this.toastr.success(`Non ${processAuditTaskNonConformance.typeDescription} deleted`);
      }
    })
  }

  private refreshProcessAuditTaskNonConformanceTableData(): void {
    this.processAuditTaskNonConformanceTableData.data = this.processAuditTaskNonConformances
      .filter(i => i.type === ProcessAuditTaskNonConformanceTypeConstant.NonConformance);
    this.processAuditTaskObservationTableData.data = this.processAuditTaskNonConformances
      .filter(i => i.type === ProcessAuditTaskNonConformanceTypeConstant.Observation);
  }

  public close() {
    this.dialogRef.close();
  }
}
