<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <mat-card appearance="outlined" class="list-card">
    <mat-card-content>
      <div class="row">
        <div class="col-sm-12">
          <button mat-flat-button color="accent" class="button accent-button" (click)="export()" [disabled]="isBusy">Export</button>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 search">
          <app-search-control [dataSource]="roleRegisterTableData"></app-search-control>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <table #roleRegisterTable mat-table [dataSource]="roleRegisterTableData" matSort matSortActive="roleGroupTitle" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="roleGroup">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="roleGroupTitle">Group</th>
              <td mat-cell *matCellDef="let roleData">{{roleData.roleGroupTitle}}</td>
            </ng-container>

            <ng-container matColumnDef="roleName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="roleName">Name</th>
              <td mat-cell *matCellDef="let roleData">{{roleData.roleName}}</td>
            </ng-container>

            <ng-container matColumnDef="reportsTo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="reportsTo">Reports to</th>
              <td mat-cell *matCellDef="let roleData">{{roleData.reportsTo}}</td>
            </ng-container>

            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="description">Description</th>
              <td mat-cell *matCellDef="let roleData">{{roleData.description}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="roleRegisterColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let roleData; columns: roleRegisterColumns;"></tr>
            <tr *matNoDataRow>
              <td [attr.colspan]="roleRegisterColumns.length" class="no-data-row">
                No roles found
              </td>
            </tr>
          </table>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
