import { Component, Inject } from "@angular/core";
import { ProcessAuditModel } from "../../../../models/process-audit-model";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { ProcessAuditService } from "../../../../services/process-audit.service";
import { AuthService } from "../../../../services/auth.service";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { MatFormField, MatLabel, MatError } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { NgIf } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { ProcessAuditStatusConstant } from "../../../../constants/process-audit-status-constant";

@Component({
    selector: 'app-process-audit-rejection-dialog',
    templateUrl: './process-audit-rejection-dialog.component.html',
    styleUrls: ['./process-audit-rejection-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatFormField, MatLabel, MatInput, FormsModule, NgIf, MatError, MatDialogActions, MatButton]
})
export class ProcessAuditRejectionDialogComponent {
  public processAudit: ProcessAuditModel;

  constructor(@Inject(MAT_DIALOG_DATA) public model: ProcessAuditModel,
    private dialogRef: MatDialogRef<ProcessAuditRejectionDialogComponent>,
    private toastr: ToastrService,
    private processAuditService: ProcessAuditService,
    public authService: AuthService) {
    this.processAudit = structuredClone(model);
  }

  public canReject(): boolean {
    return this.model.status === ProcessAuditStatusConstant.PendingClosure &&
      (this.authService.currentUser?.isAdministrator ||
      this.authService.currentUser?.isCompanyAdministrator ||
      this.authService.currentUser?.userId == this.model.closureApproverUser?.userId)
  }

  public rejectAudit() {
    if (!this.processAudit.rejectionComment) {
      this.toastr.error("Rejection Comment is required.");
      return;
    } else if (!confirm("Are you sure you want to reject this Audit?")) {
      return;
    }

    this.processAuditService.rejectProcessAuditClosure(this.processAudit.processAuditId, this.processAudit.rejectionComment).subscribe({
      next: () => {
        this.toastr.success("Audit Rejected");
        this.dialogRef.close(this.processAudit.rejectionComment);
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
