import { Component, Inject } from "@angular/core";
import { ProcessAuditTaskNonConformanceModel } from "../../../../models/process-audit-task-non-conformance-model";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { ProcessAuditTaskNonConformanceService } from "../../../../services/process-audit-task-non-conformance.service";
import { AuthService } from "../../../../services/auth.service";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { MatFormField, MatLabel, MatError } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { NgIf } from "@angular/common";
import { MatButton } from "@angular/material/button";
import { ProcessAuditStatusConstant } from "../../../../constants/process-audit-status-constant";

@Component({
    selector: 'app-process-audit-task-non-conformance-reject-dialog',
    templateUrl: './process-audit-task-non-conformance-reject-dialog.component.html',
    styleUrls: ['./process-audit-task-non-conformance-reject-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatFormField, MatLabel, MatInput, FormsModule, NgIf, MatError, MatDialogActions, MatButton]
})
export class ProcessAuditTaskNonConformanceRejectDialogComponent {
  public nonConformance: ProcessAuditTaskNonConformanceModel;

  constructor(@Inject(MAT_DIALOG_DATA) public model: ProcessAuditTaskNonConformanceModel,
    private dialogRef: MatDialogRef<ProcessAuditTaskNonConformanceRejectDialogComponent>,
    private toastr: ToastrService,
    private processAuditTaskNonConformanceService: ProcessAuditTaskNonConformanceService,
    public authService: AuthService) {
    this.nonConformance = structuredClone(model);
  }

  public canReject(): boolean {
    return this.model.status === ProcessAuditStatusConstant.PendingClosure &&
      (this.authService.currentUser?.isAdministrator ||
      this.authService.currentUser?.isCompanyAdministrator ||
      this.authService.currentUser?.userId == this.model.closureApproverUser?.userId)
  }

  public rejectNonConformance() {
    if (!this.nonConformance.rejectionComment) {
      this.toastr.error("Rejection Comment is required.");
      return;
    } else if (!confirm(`Are you sure you want to reject this ${this.model.typeDescription}?`)) {
      return;
    }

    this.processAuditTaskNonConformanceService.rejectNonConformanceForClosure(this.nonConformance.processAuditTaskNonConformanceId, this.nonConformance.rejectionComment).subscribe({
      next: (response: ProcessAuditTaskNonConformanceModel) => {
        this.toastr.success(`${response.typeDescription} rejected for closure`);
        this.dialogRef.close(response);
      }
    });
  }

  public close() {
    this.dialogRef.close();
  }
}
