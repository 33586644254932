<section class="dialog-container">
  <div>
    <h2 mat-dialog-title>Close {{model.typeDescription}}</h2>
  </div>

  <mat-dialog-content>
    <mat-form-field color="accent">
      <mat-label>Closed Comment</mat-label>
      <textarea matInput
                rows="5"
                placeholder="Enter Closed Comment..."
                name="closedComment"
                [(ngModel)]="nonConformance.closedComment"
                #closedComment="ngModel"
                required></textarea>
      <mat-error *ngIf="closedComment.invalid">
        Closed Comment is required
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="button" mat-raised-button (click)="close()">Cancel</button>
    <button *ngIf="canClose()"
            mat-raised-button
            class="button green-button"
            (click)="closeNonConformance()">
      Close {{ model.typeDescription }}
    </button>
  </mat-dialog-actions>
</section>
