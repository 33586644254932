<mat-form-field color="accent" *ngIf="!isDisabled">
  <mat-label>{{label}}</mat-label>
  <mat-chip-grid #chipList>
    <mat-chip-row *ngFor="let option of selectedOptions"
                     [removable]="removable"
                     (removed)="remove(option)">
      {{option.displayValue}}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip-row>
    <input placeholder="{{placeholder}}"
           matInput
           #optionInput
           [ngModel]="searchText"
           (ngModelChange)="applyFilter($event)"
           [matAutocomplete]="auto"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           [matChipInputAddOnBlur]="addOnBlur"
           (blur)="onBlur()" />
  </mat-chip-grid>
  <mat-autocomplete autoSelectActiveOption="false" hideSingleSelectionIndicator="true" #auto="matAutocomplete" (optionSelected)="optionSelected($event, auto)">
    <mat-option *ngFor="let option of filteredOptions">
      {{option.displayValue}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>


<mat-form-field color="accent" class="disabled" *ngIf="isDisabled">
  <mat-label class="disabled-label">{{label}}</mat-label>
  <mat-chip-grid #disabledChipGrid>
    <mat-chip-row *ngFor="let option of selectedOptions" selectable="false">
      {{option.displayValue}}
    </mat-chip-row>
    <input matInput disabled [matChipInputFor]="disabledChipGrid" />
  </mat-chip-grid>
</mat-form-field>
